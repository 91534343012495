@if (service.vm$ | async; as vm) {
  <celum-dialog class="sacc-edit-account-member-dialog" data-test-edit-account-member-dialog (onCloseAll)="dialogRef.close(false)">
    <celum-dialog-header class="sacc-edit-account-member-dialog_top">
      <span>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.HEADER' | translate }}</span>
    </celum-dialog-header>

    <form class="sacc-edit-account-member-dialog_content" [formGroup]="accountMemberForm">
      <div class="sacc-edit-account-member-dialog_section">
        <h3>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.HEADER' | translate }}</h3>
        <div class="sacc-edit-account-member-dialog_details">
          <celum-user-avatar
            class="sacc-edit-account-member-dialog_details-avatar"
            data-test-edit-account-member-dialog-user-avatar
            [accountAccessToken]="vm.token"
            [class.sacc-edit-account-member-dialog_details-avatar--disabled]="vm.isUserDeactivated"
            [size]="AVATAR_SIZE.xl"
            [user]="vm.editedUser"></celum-user-avatar>
          <div class="sacc-edit-account-member-dialog_details-subcontainer">
            <span class="sacc-edit-account-member-dialog_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.NAME' | translate }}</span>
            <div
              class="sacc-edit-account-member-dialog_details-text"
              data-test-edit-account-member-dialog-details-name
              spaceAwareTooltip
              [matTooltip]="vm.editedUser.firstName + ' ' + data.editedUser.lastName">
              {{ vm.editedUser.firstName + ' ' + data.editedUser.lastName }}
            </div>
            <span class="sacc-edit-account-member-dialog_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.EMAIL' | translate }}</span>
            <div
              class="sacc-edit-account-member-dialog_details-text"
              data-test-edit-account-member-dialog-details-email
              spaceAwareTooltip
              [matTooltip]="vm.editedUser.email">
              {{ vm.editedUser.email }}
            </div>
            <div class="sacc-edit-account-member-dialog_details-role-container">
              <span class="sacc-edit-account-member-dialog_header sacc-edit-account-member-dialog_details-role-container-header">
                {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.ROLE' | translate }}
              </span>
              <celum-icon
                class="sacc-edit-account-member-dialog_details-info-icon"
                data-test-edit-account-member-dialog-role-details-info-icon
                matTooltip="{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.TOOLTIPS.ROLE_INFO' | translate }}"
                [clickable]="false"
                [configuration]="icons.info"></celum-icon>
            </div>
            @if (!vm.isSelf && !vm.isEditingOwner) {
              <mat-form-field
                class="sacc-edit-account-member-dialog_select sacc-form-field sacc-form-field--no-error"
                data-test-edit-account-member-dialog-details-role-form-field
                [matTooltip]="vm.isUserDeactivated ? ('COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.TOOLTIPS.ROLE_DEACTIVATED' | translate) : null">
                <mat-select
                  data-test-edit-account-member-dialog-details-role-select
                  [className]="'sacc-edit-account-member-dialog_select-item'"
                  [formControl]="accountMemberForm.controls.role"
                  [panelClass]="'select-panel'"
                  [value]="vm.editedUser.role">
                  <mat-option data-test-edit-account-member-dialog-details-role-select-option-manager value="MANAGER">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.USER_ROLE.MANAGER' | translate }}
                  </mat-option>
                  <mat-option data-test-edit-account-member-dialog-details-role-select-option-member value="MEMBER">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.USER_ROLE.MEMBER' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            }
            @if (vm.isSelf || vm.isEditingOwner) {
              <span
                class="sacc-edit-account-member-dialog_details-role-label"
                data-test-edit-account-member-dialog-role-readonly-label
                [matTooltip]="
                  (vm.isSelf
                    ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.TOOLTIPS.CANNOT_EDIT_ROLE_IF_OWNER'
                    : vm.isEditingOwner
                      ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.TOOLTIPS.CANNOT_EDIT_ROLE_OWNER'
                      : ''
                  ) | translate
                ">
                {{ getReadonlyRoleLabel(vm.editedUser, vm.accountAccess) | translate }}
              </span>
            }
          </div>
        </div>
        <sacc-account-user-status-switcher
          class="sacc-edit-account-member-dialog_status-switcher"
          [config]="vm.statusSwitcherConfig"
          (clicked)="onStatusSwitcherClicked()"></sacc-account-user-status-switcher>

        @if (vm.hasExperienceLicense || vm.hasWorkroomLicense) {
          <div class="sacc-edit-account-member-dialog_privileges-header">
            <span class="sacc-edit-account-member-dialog_privileges-header-text">
              {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.HEADER' | translate }}
            </span>
            <sacc-icon-button
              #privilegesInfoIcon
              class="sacc-edit-account-member-dialog_privileges-info-icon"
              data-test-sacc-edit-account-member-dialog-privileges-info-icon
              [class.sacc-edit-account-member-dialog_privileges-info-icon--popup-open]="vm.isPopupOpen"
              [iconConfiguration]="icons.question"
              [matTooltip]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_PRIVILEGES_INFO_BUTTON' | translate"
              (click)="showPrivilegesInfoPanel()"></sacc-icon-button>
          </div>

          @if (vm.hasExperienceLicense) {
            <div class="sacc-edit-account-member-dialog_privileges sacc-edit-account-member-dialog_privileges-experience">
              <!-- TODO @CST: Use correct showIndicator-value in https://celum.atlassian.net/browse/SACC-3163 -->
              <sacc-privilege-icon
                class="sacc-edit-account-member-dialog_privileges-icon"
                [disabled]="vm.isUserDeactivated"
                [iconType]="
                  accountMemberForm.controls.experiencePrivilege.value === ExperiencePrivilege.NONE
                    ? PrivilegeIconType.EXPERIENCE_NOT_APPLIED
                    : PrivilegeIconType.EXPERIENCE
                "
                [showIndicator]="accountMemberForm.get('experiencePrivilege').value === ExperiencePrivilege.FULL_ACCESS"
                [tooltip]="
                  vm.isUserDeactivated
                    ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_TOGGLE_DEACTIVATED'
                    : accountMemberForm.controls.experiencePrivilege.value === ExperiencePrivilege.FULL_ACCESS
                      ? 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS'
                      : 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS_DISABLED'
                "></sacc-privilege-icon>
              <span class="sacc-edit-account-member-dialog_privileges-label" [class.disabled]="vm.isUserDeactivated">
                {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.LABEL' | translate }}
              </span>
              <mat-form-field
                class="sacc-edit-account-member-dialog_select sacc-edit-account-member-dialog_select-experience sacc-form-field sacc-form-field--no-error"
                [matTooltip]="vm.isUserDeactivated ? ('COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_TOGGLE_DEACTIVATED' | translate) : null">
                <mat-select
                  data-test-edit-account-member-dialog-details-experience-privilege-select
                  [className]="'sacc-edit-account-member-dialog_select-item'"
                  [formControl]="accountMemberForm.controls.experiencePrivilege"
                  [panelClass]="'select-panel'"
                  [value]="vm.editedUser.privileges.experience">
                  <mat-option
                    data-test-edit-account-member-dialog-details-experience-privilege-select-option-full-access
                    [value]="ExperiencePrivilege.FULL_ACCESS">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.EXPERIENCE_PRIVILEGE.FULL_ACCESS' | translate }}
                  </mat-option>
                  <mat-option data-test-edit-account-member-dialog-details-experience-privilege-select-option-none [value]="ExperiencePrivilege.NONE">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.EXPERIENCE_PRIVILEGE.NONE' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          }

          @if (vm.hasWorkroomLicense) {
            <div class="sacc-edit-account-member-dialog_privileges">
              <sacc-privilege-icon
                class="sacc-edit-account-member-dialog_privileges-icon"
                [disabled]="vm.isUserDeactivated"
                [iconType]="PrivilegeIconType.WORK"
                [showIndicator]="accountMemberForm.get('workroomPrivilege').value === WorkroomPrivilege.CURATOR"
                [tooltip]="
                  vm.isUserDeactivated
                    ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON_DEACTIVATED'
                    : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON'
                "></sacc-privilege-icon>
              <span class="sacc-edit-account-member-dialog_privileges-label" [class.disabled]="vm.isUserDeactivated">
                {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.LABEL' | translate }}
              </span>
              <mat-form-field
                class="sacc-edit-account-member-dialog_select sacc-edit-account-member-dialog_select-work sacc-form-field sacc-form-field--no-error"
                [matTooltip]="vm.isUserDeactivated ? ('COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_TOGGLE_DEACTIVATED' | translate) : null">
                <mat-select
                  data-test-edit-account-member-dialog-details-work-privilege-select
                  [className]="'sacc-edit-account-member-dialog_select-item'"
                  [formControl]="accountMemberForm.controls.workroomPrivilege"
                  [panelClass]="'select-panel'"
                  [value]="vm.editedUser.privileges.work">
                  <mat-option data-test-edit-account-member-dialog-details-work-privilege-select-option-curator [value]="WorkroomPrivilege.CURATOR">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.WORK_PRIVILEGE.CURATOR' | translate }}
                  </mat-option>
                  <mat-option data-test-edit-account-member-dialog-details-work-privilege-select-option-participant [value]="WorkroomPrivilege.PARTICIPANT">
                    {{ 'COMPONENTS.ACCOUNT_USER_TABLE.WORK_PRIVILEGE.PARTICIPANT' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          }
        }
      </div>
      <div class="sacc-edit-account-member-dialog_section">
        <sacc-group-selector
          [accountAccess]="vm.accountAccess"
          [user]="vm.editedUser"
          (addedGroupsChanged)="service.patchState({ addedGroups: $event })"
          (removedGroupsChanged)="service.patchState({ removedGroups: $event })"></sacc-group-selector>
      </div>
    </form>

    <celum-dialog-footer>
      <sacc-icon-text-button
        class="sacc-edit-account-member-dialog_footer-button-cancel"
        data-test-edit-account-member-dialog-button-cancel
        [label]="'COMMON.CANCEL'"
        [icon]="'cancel-m'"
        (clicked)="dialogRef.close(false)"></sacc-icon-text-button>
      @if (!vm.hasDriveLicense || service.getChangedValues(accountMemberForm.value, data.editedUser).status !== AccountAccessStatus.INACTIVE) {
        <sacc-icon-text-button
          data-test-edit-account-member-dialog-button-confirm
          [disabled]="!(accountMemberForm.valid && (accountMemberForm.dirty || vm.addedGroups.length > 0 || vm.removedGroups.length > 0))"
          [icon]="'check-m'"
          [label]="'COMMON.SAVE'"
          (clicked)="updateAccountUser()"></sacc-icon-text-button>
      }
      @if (vm.hasDriveLicense && service.getChangedValues(accountMemberForm.value, data.editedUser).status === AccountAccessStatus.INACTIVE) {
        <sacc-icon-text-button
          data-test-edit-account-member-dialog-button-confirm-menu
          [label]="'COMMON.SAVE'"
          [icon]="'check-m'"
          [matMenuTriggerFor]="menuRef"
          [disabled]="!(accountMemberForm.valid && (accountMemberForm.dirty || vm.addedGroups.length > 0 || vm.removedGroups.length > 0))">
          <mat-menu #menuRef="matMenu" [class]="'dialog-confirm-menu'" [yPosition]="'above'">
            <button mat-menu-item data-test-edit-account-member-dialog-menu-confirm-keep-files (click)="updateAccountUser(true)">
              <span>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.CONFIRM_KEEP_SYNCED_FILES' | translate }}</span>
            </button>
            <button mat-menu-item data-test-edit-account-member-dialog-menu-confirm-remove-files (click)="updateAccountUser(false)">
              <span>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.CONFIRM_REMOVE_SYNCED_FILES' | translate }}</span>
            </button>
          </mat-menu>
        </sacc-icon-text-button>
      }
    </celum-dialog-footer>
  </celum-dialog>
}
