import { Language, Locale } from '@celum/sacc/domain';

export class LocaleUtils {
  public static availableLanguages = new Map([
    [Locale.EN.toString(), Language.ENGLISH.toString()],
    [Locale.DE.toString(), Language.DEUTSCH.toString()]
  ]);

  /**
   * Get the locale for the current user.
   * todo: extend this to read information from logged in user or from a cookie information
   */
  public static getLocale(): string {
    const lang = navigator.language.substring(0, 2);
    return Array.from(LocaleUtils.availableLanguages.keys()).includes(lang) ? lang : LocaleUtils.getDefaultLocale();
  }

  /**
   * Fallback locale if the selected locale is not available
   */
  public static getDefaultLocale(): string {
    return 'en';
  }
}
