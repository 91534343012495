import { createSelector } from '@ngrx/store';

import { Federation } from '@celum/sacc/domain';

import { federationAdapter, FederationState } from './federation.state';
import { AppState } from '../app.state';

export const selectFederationState = (state: AppState) => state.federations;

export const selectFederationsAll = createSelector(selectFederationState, federationAdapter.getSelectors().selectAll);

export const selectFederationCount = createSelector(selectFederationState, federationState => federationState.totalCount);

export const selectFederationFilteredCount = createSelector(selectFederationState, federationState => federationState.filterCount);

export const selectFederationsAllLoaded = createSelector(
  selectFederationState,
  selectFederationsAll,
  (federationState, federations) => federations.length > 0 && !federationState.continuationToken
);

export const selectFederationsLoading = createSelector(selectFederationState, federationState => federationState.loading);

export const selectFederationFilter = createSelector(selectFederationState, federationState => federationState.filter);

export const selectFederationNextBatchParams = createSelector(selectFederationState, federationState => {
  return {
    count: federationAdapter.getSelectors().selectAll(federationState).length,
    sort: federationState.sort,
    continuationToken: federationState.continuationToken
  };
});

export const selectFederationByFederationId = (federationId: string) =>
  createSelector(selectFederationState, (federationState: FederationState): Federation => federationState.entities[federationId]);
