import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import {
  AccountEffects,
  accountReducer,
  AccountUserEffects,
  accountUserReducer,
  AppState,
  AuthEffects,
  FederationEffects,
  federationReducer,
  initialAppState,
  InvitationEffects,
  invitationReducer,
  LoaderEffects,
  loaderReducer,
  NotificationEffects,
  RepositoryEffects,
  repositoryReducer,
  UserEffects,
  usersReducer
} from '@celum/sacc/shared';

const reducers: ActionReducerMap<AppState> = {
  users: usersReducer,
  loading: loaderReducer,
  accounts: accountReducer,
  accountUsers: accountUserReducer,
  invitations: invitationReducer,
  repositories: repositoryReducer,
  federations: federationReducer
};

const effects = [
  AccountEffects,
  UserEffects,
  NotificationEffects,
  AuthEffects,
  LoaderEffects,
  AccountUserEffects,
  InvitationEffects,
  RepositoryEffects,
  FederationEffects
];

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot(reducers, {
      initialState: initialAppState,
      runtimeChecks: {
        strictActionImmutability: false,
        strictStateImmutability: false
      }
    }),
    EffectsModule.forRoot(effects)
  ]
})
export class AppStoreModule {}
