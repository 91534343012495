import { createReducer, on } from '@ngrx/store';

import { accountUserActions } from './account-user.action';
import { accountUserAdapter, initialAccountUserState } from './account-user.state';

export const accountUserReducer = createReducer(
  initialAccountUserState,
  on(accountUserActions.fetchBatch, state => ({ ...state, loading: true })),
  on(accountUserActions.fetchBatchFailure, state => ({ ...state, loading: false })),
  on(accountUserActions.fetchBatchSuccess, (state, { batch }) => ({
    ...accountUserAdapter.upsertMany(batch.entities, state),
    continuationToken: batch.continuationToken,
    totalCount: batch.totalCount,
    filterCount: batch.filterCount,
    loading: false
  })),
  on(accountUserActions.search, state => ({
    ...state,
    loading: true,
    continuationToken: state.continuationToken
  })),
  on(accountUserActions.filterChanged, (state, action) => ({
    ...accountUserAdapter.removeAll(state),
    loading: true,
    continuationToken: '',
    filter: action.filter
  })),
  on(accountUserActions.searchFailure, state => ({ ...state, loading: false })),
  on(accountUserActions.searchSuccess, (state, { batch }) => {
    return {
      ...accountUserAdapter.upsertMany(batch.entities, state),
      continuationToken: batch.continuationToken,
      totalCount: batch.totalCount,
      filterCount: batch.filterCount,
      loading: false
    };
  }),
  on(accountUserActions.sortChanged, (state, { sort }) => ({ ...initialAccountUserState, sort })),
  on(accountUserActions.resetAccountTable, state => ({ ...initialAccountUserState, filter: state.filter })),
  on(accountUserActions.resetAccountTableFilter, state => ({ ...state, filter: '' })),
  on(accountUserActions.updateSuccess, (state, { accountUser }) => ({
    ...accountUserAdapter.upsertOne(accountUser, state)
  })),
  on(accountUserActions.removeSuccess, (state, { accountUserId }) => ({
    ...accountUserAdapter.removeOne(accountUserId, {
      ...state,
      totalCount: state.totalCount - 1,
      filterCount: state.filterCount - 1
    })
  }))
);
