@if (service.vm$ | async; as vm) {
  <celum-dialog class="sacc-edit-invitation-dialog" data-test-edit-invitation-dialog (onCloseAll)="dialogRef.close()">
    <celum-dialog-header class="sacc-edit-invitation-dialog_top">
      <span>{{ 'COMPONENTS.EDIT_INVITATION_DIALOG.HEADER' | translate }}</span>
    </celum-dialog-header>

    <form class="sacc-edit-invitation-dialog_content" [formGroup]="invitationForm">
      <div class="sacc-edit-invitation-dialog_section">
        <h3>{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.HEADER' | translate }}</h3>
        <div class="sacc-edit-invitation-dialog_details">
          <celum-user-avatar
            class="sacc-edit-invitation-dialog_details-avatar"
            data-test-edit-invitation-dialog-user-avatar
            [accountAccessToken]="vm.token"
            [size]="AVATAR_SIZE.xl"
            [user]="vm.editedInvitation"></celum-user-avatar>
          <div class="sacc-edit-invitation-dialog_details-subcontainer">
            <span class="sacc-edit-invitation-dialog_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.DETAILS.INFO.EMAIL' | translate }}</span>
            <div class="sacc-edit-invitation-dialog_details-text" data-test-edit-invitation-dialog-details-email>{{ vm.editedInvitation.email }}</div>
            <span class="sacc-edit-invitation-dialog_header">{{ 'COMPONENTS.EDIT_INVITATION_DIALOG.DETAILS.INFO.INVITED_BY' | translate }}</span>
            <div class="sacc-edit-invitation-dialog_details-text" data-test-edit-invitation-dialog-details-invited-by>
              {{ vm.editedInvitation.inviterEmail }}
            </div>
          </div>
        </div>
        <sacc-account-user-status-switcher [config]="vm.statusSwitcherConfig" (clicked)="onStatusSwitcherClicked()"></sacc-account-user-status-switcher>
        <h3 class="sacc-edit-invitation-dialog_privileges-header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.HEADER' | translate }}</h3>
        <div class="sacc-edit-invitation-dialog_privileges">
          <!-- TODO: Replace with new component in https://celum.atlassian.net/browse/SACC-3163-->
          <!-- <celum-icon
          class="sacc-edit-invitation-dialog_privileges-icon"
          [configuration]="invitationForm.controls.experience.value ? icons.experience : icons.experienceInactive"
          [matTooltip]="
            (invitationForm.controls.experience.value
              ? 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS'
              : 'COMPONENTS.CELUM_SERVICE.EXPERIENCE_BASIC_ACCESS_DISABLED'
            ) | translate
          "></celum-icon>-->
          <span class="sacc-edit-invitation-dialog_privileges-label">
            {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.LABEL' | translate }}
          </span>
          <!-- TODO: Replace with new component in https://celum.atlassian.net/browse/SACC-3163-->
          <!--<mat-slide-toggle
          class="sacc-edit-invitation-dialog_privileges-toggle"
          data-test-edit-invitation-dialog-privileges-toggle
          [checked]="vm.hasExperienceAccess"
          [formControl]="invitationForm.controls.experience"
          [matTooltip]="
            (invitationForm.controls.experience.value
              ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_TOGGLE_ACTIVE'
              : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PRIVILEGES.TOOLTIP_TOGGLE_INACTIVE'
            ) | translate
          "></mat-slide-toggle>-->
        </div>
        <h3 class="sacc-edit-invitation-dialog_privileges-header">
          {{ 'COMPONENTS.ACCOUNT_USER_TABLE.CELUM_SERVICE_PERMISSIONS.TABLE_HEADER' | translate }}
        </h3>
        <div class="sacc-edit-invitation-dialog_privileges">
          <celum-icon
            class="sacc-edit-invitation-dialog_privileges-icon"
            [configuration]="invitationForm.controls.privileges?.controls.work?.value ? icons.work : icons.workInactive"
            [matTooltip]="
              (invitationForm.controls.privileges?.controls.work?.value
                ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON'
                : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON_INACTIVE'
              ) | translate
            "></celum-icon>
          <span class="sacc-edit-invitation-dialog_privileges-label">
            {{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.LABEL' | translate }}
          </span>
          <!-- TODO: Fix with re-introduction of invitation dialog in https://celum.atlassian.net/browse/SACC-3163-->
          <!--<sacc-celum-service-permissions
          data-test-edit-invitation-dialog-permissions-control
          [disabledTooltip]="'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.PERMISSIONS.TOOLTIP_ICON_DEACTIVATED' | translate"
          [form]="invitationForm.get('work')"
          [hasWorkroomLicense]="vm.hasWorkroomLicense"
          [workroomPermissions]="vm.editedInvitation.workroomPermissions"></sacc-celum-service-permissions>-->
        </div>
      </div>
      <div class="sacc-edit-invitation-dialog_section">
        <sacc-group-selector
          [accountAccess]="vm.accountAccess"
          [user]="vm.editedInvitation"
          (addedGroupsChanged)="service.patchState({ addedGroups: $event })"
          (removedGroupsChanged)="service.patchState({ removedGroups: $event })"></sacc-group-selector>
      </div>
    </form>

    <celum-dialog-footer>
      <sacc-icon-text-button
        class="sacc-edit-invitation-dialog_footer-button-cancel"
        data-test-edit-invitation-dialog-button-cancel
        [label]="'COMMON.CANCEL'"
        [icon]="'cancel-m'"
        (clicked)="dialogRef.close(false)"></sacc-icon-text-button>
      <sacc-icon-text-button
        data-test-edit-invitation-dialog-button-confirm
        [disabled]="!(invitationForm.valid && (invitationForm.dirty || vm.addedGroups.length > 0 || vm.removedGroups.length > 0))"
        [icon]="'check-m'"
        [label]="'COMMON.SAVE'"
        (clicked)="updateInvitation()"></sacc-icon-text-button>
    </celum-dialog-footer>
  </celum-dialog>
}
