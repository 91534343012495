import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import imageCompression from 'browser-image-compression';
import { catchError, from, Observable, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { Account, SaccProperties } from '@celum/sacc/domain';
import { ErrorService } from '@celum/shared/util';

import { SaccBlobService } from './sacc-blob.service';
import { accountActions, userActions } from '../store';

@Injectable({ providedIn: 'root' })
export class AccountResourceService {
  constructor(
    private httpClient: HttpClient,
    private storageService: SaccBlobService,
    private errorService: ErrorService,
    private store$: Store
  ) {}

  public getById(id: string): Observable<Account> {
    return this.httpClient.get<Account>(`${SaccProperties.properties.apiUrl}/accounts/${id}`);
  }

  public update(account: Account, logoFile: File): Observable<Account> {
    return this.httpClient.patch<Account>(`${SaccProperties.properties.apiUrl}/accounts/${account.id}`, account).pipe(
      switchMap(updatedAccount => (logoFile ? this.updateLogo(updatedAccount, logoFile) : of(updatedAccount))),
      tap((result: Account) => this.store$.dispatch(accountActions.getOneSuccess({ account: result }))),
      tap(() => this.store$.dispatch(userActions.getDetails()))
    );
  }

  public updateCompanyDetails(account: Account, logoFile?: File): Observable<Account> {
    return this.httpClient
      .patch<Account>(`${SaccProperties.properties.apiUrl}/accounts/${account.id}/companyDetails`, {
        ...account.companyDetails
      })
      .pipe(
        switchMap(updatedAccount => (logoFile ? this.updateLogo(updatedAccount, logoFile) : of(updatedAccount))),
        tap((result: Account) => this.store$.dispatch(accountActions.getOneSuccess({ account: result }))),
        tap(() => this.store$.dispatch(userActions.getDetails()))
      );
  }

  public deleteAccount(accountId: string): Observable<void> {
    return this.httpClient.delete<void>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}`);
  }

  private updateLogo(account: Account, logoFile: File): Observable<Account> {
    return from(
      imageCompression(logoFile, {
        maxSizeMB: SaccProperties.properties.pictureUpload.maxSize,
        maxWidthOrHeight: SaccProperties.properties.pictureUpload.maxWidthOrHeight
      })
    ).pipe(
      switchMap(compressedFile => this.storageService.uploadLogo(account.id, compressedFile)),
      map(accountLogoDownloadLink => ({
        ...account,
        logoUri: `${SaccProperties.properties.apiUrl}/accounts/${account.id}/logo`,
        accountLogoDownloadLink
      })),
      catchError(error => {
        this.errorService.error(`AccountService`, 'SERVICES.ACCOUNT.EFFECTS.ACCOUNT_LOGO_UPLOAD_FAILURE', error, false, true);
        return of(account);
      })
    );
  }
}
