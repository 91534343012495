import { Component, HostBinding, Input } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Store } from '@ngrx/store';

import { AppState } from '@celum/sacc/shared';

@Component({
  selector: 'sacc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [FlexModule, MatProgressSpinnerModule]
})
export class LoaderComponent {
  @HostBinding('hidden')
  public isHidden = true;
  private isAlwaysVisible = false;

  constructor(private store$: Store<AppState>) {
    this.store$.select('loading').subscribe(loading => {
      if (this.isAlwaysVisible) {
        return;
      }
      this.isHidden = !loading;
    });
  }

  @Input() set alwaysVisible(alwaysVisible: boolean) {
    this.isAlwaysVisible = alwaysVisible;
    this.isHidden = !alwaysVisible;
  }
}
