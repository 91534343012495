import { createSelector } from '@ngrx/store';

import { accountUserAdapter } from './account-user.state';
import { AppState } from '../app.state';

export const selectAccountUserState = (state: AppState) => state.accountUsers;

export const selectAccountUsersAll = createSelector(selectAccountUserState, accountUserAdapter.getSelectors().selectAll);

export const selectAccountUserCount = createSelector(selectAccountUserState, accountUserState => accountUserState.totalCount);

export const selectAccountUserFilterCount = createSelector(selectAccountUserState, accountUserState => accountUserState.filterCount);

export const selectAccountUsersLoading = createSelector(selectAccountUserState, accountUserState => accountUserState.loading);

export const selectAccountUsersAllLoaded = createSelector(
  selectAccountUserState,
  selectAccountUsersAll,
  (accountUserState, allAccountUsers) => allAccountUsers.length > 0 && !accountUserState.continuationToken
);

export const selectAccountUserFilter = createSelector(selectAccountUserState, accountUserState => accountUserState.filter);

export const selectAccountUserNextBatchParams = createSelector(selectAccountUserState, accountUserState => {
  return {
    count: accountUserAdapter.getSelectors().selectAll(accountUserState).length,
    sort: accountUserState.sort,
    continuationToken: accountUserState.continuationToken
  };
});
