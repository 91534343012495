import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

import { CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { CelumChipComponent, CelumChipSetComponent } from '@celum/shared/ui';

@Component({
  selector: 'sacc-search-and-select',
  templateUrl: './search-and-select.component.html',
  styleUrls: ['./search-and-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,

    MatAutocompleteModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,

    CelumChipComponent,
    CelumChipSetComponent,
    CelumIconModule
  ]
})
export class SearchAndSelectComponent implements AfterViewInit, OnChanges {
  @Output() public readonly elementSelected = new EventEmitter<any>();
  @Output() public readonly elementDeselected = new EventEmitter<any>();
  @Output() public readonly filterChanged = new EventEmitter<any>();
  @Input() public elements: any[];
  @Input() public placeHolder = 'COMPONENTS.SEARCH_AND_SELECT.SEARCH';
  @Input() public allElementsLoaded: boolean;
  @Input() public elementsLoading: boolean;
  public filteredElements: any[];
  public elementControl = new UntypedFormControl();
  @Input() public showSelectedElements = true;

  protected icons: { [key: string]: IconConfiguration } = {
    clear: new IconConfiguration('cancel-m').withColor(ColorConstants.BLUE_GRAY_600).withIconSize(20),
    arrowDown: new IconConfiguration('arrow-down-xs').withColor(ColorConstants.BLUE_GRAY_500).withIconSize(10)
  };
  protected internalSelectedElements: any[] = [];

  private destroyRef = inject(DestroyRef);

  @ViewChild('autocomplete') private autocomplete: any;

  @Input() public set selectedElements(selectedElements: any[]) {
    this.internalSelectedElements = [...selectedElements].sort((a, b) => this.nameMapping(a).localeCompare(this.nameMapping(b)));
  }
  @Input() public idMapping: (element: any) => string = e => e.id || '';
  @Input() public nameMapping: (element: any) => string = e => e.name || '';

  public ngOnChanges({ elements, selectedElements }: SimpleChanges): void {
    if (elements || selectedElements) {
      this.elements = this.elements.concat(this.internalSelectedElements);
      this.elements = this.elements.filter((e, i) => this.elements.map(el => this.idMapping(el)).indexOf(this.idMapping(e)) === i);
      this.sortAndFilter();
    }
  }

  // Has to be defined as arrow function so that track gets "this" correctly. Using trackByFn.bind doesn't work anymore with the new @if syntax
  public trackByFn = (_: number, entry: any) => {
    return this.idMapping(entry);
  };

  public optionClicked(event: Event, element: any): void {
    event.stopPropagation();
    this.toggleSelection(element);
  }

  public isSelected(element: any): boolean {
    return this.internalSelectedElements.some(r => this.idMapping(r) === this.idMapping(element));
  }

  public toggleSelection(element: any): void {
    if (!this.isSelected(element)) {
      this.internalSelectedElements.push(element);
      this.elementSelected.emit(element);
      this.internalSelectedElements.sort((a, b) => this.nameMapping(a).localeCompare(this.nameMapping(b)));
    } else {
      const i = this.internalSelectedElements.findIndex(value => this.idMapping(value) === this.idMapping(element));
      this.internalSelectedElements.splice(i, 1);
      this.elementDeselected.emit(element);
    }
  }

  public clearInput(): void {
    this.elementControl.setValue('');
    setTimeout(() => {
      this.autocomplete.nativeElement.blur();
      this.autocomplete.nativeElement.focus();
    });
  }

  public remove(element: any): void {
    const i = this.internalSelectedElements.findIndex(value => this.idMapping(value) === this.idMapping(element));
    this.internalSelectedElements.splice(i, 1);
    this.elementDeselected.emit(element);
  }

  public sortAndFilter(): void {
    this.elements.sort(this.compareBySelected());
    const filterStr = this.elementControl.value;
    this.filteredElements = filterStr
      ? this.elements.filter(option => this.nameMapping(option).toLowerCase().indexOf(filterStr.toLowerCase()) >= 0)
      : this.elements;
  }

  public ngAfterViewInit(): void {
    this.filterChanged.emit('');
    this.elementControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500)).subscribe(value => this.filterChanged.emit(value));
  }

  private compareBySelected(): (r1: any, r2: any) => number {
    return (r1: any, r2: any) => (this.isSelected(r1) === this.isSelected(r2) ? 0 : this.isSelected(r1) ? -1 : 1);
  }
}
