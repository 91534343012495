import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';

import { TranslationHelper } from '@celum/ng2base';

@Component({
  selector: 'sacc-privilege-info-dialog',
  templateUrl: './privilege-info-dialog.component.html',
  styleUrl: './privilege-info-dialog.component.scss',
  standalone: true,
  imports: [TranslateModule]
})
export class PrivilegeInfoDialogComponent {
  protected urlTarget: string;
  constructor(translateService: TranslationHelper) {
    translateService
      .currentLocaleStream()
      .pipe(takeUntilDestroyed())
      .subscribe(
        locale =>
          (this.urlTarget = `https://help.celum.com/${locale === 'de' ? 'de' : 'en'}/topic.htm#t=mergedProjects%2FcloudAccount%2FtocTopics%2FManage_celum_organization_member_roles.htm`)
      );
  }
}
