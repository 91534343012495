import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { Constants } from '../../constants';
import { repositoryActions } from '../../store/repository/repository.action';
import { invitationActions } from '../invitation/invitation.action';

@Injectable()
export class AuthEffects implements OnDestroy {
  public finishCelumServiceAccountAccessRequestAsApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitationActions.finishCelumServiceAccountAccessRequestAsApproved),
      map(action => ({
        redirectUrl: sessionStorage.getItem(Constants.REDIRECT_URL),
        accountId: action.accountId
      })),
      filter(({ redirectUrl, accountId }) => !!redirectUrl),
      map(({ redirectUrl, accountId }) => repositoryActions.requestSafeRedirect({ url: redirectUrl, accountId }))
    )
  );

  public finishCelumServiceAccountAccessRequestAsNotYetApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invitationActions.finishCelumServiceAccountAccessRequestAsNotYetApproved),
        map(action => ({ action, redirectUrl: sessionStorage.getItem(Constants.REDIRECT_URL) })),
        filter(actionWithRedirectUrl => !!actionWithRedirectUrl.redirectUrl),
        tap(actionWithRedirectUrl => {
          sessionStorage.removeItem(Constants.REDIRECT_URL);
          const queryParams = {
            queryParams: {
              redirectUrl: actionWithRedirectUrl.redirectUrl,
              accountId: actionWithRedirectUrl.action.accountId
            }
          };
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          actionWithRedirectUrl.action.firstRequest
            ? this.router.navigate(['./accounts/first-request'], queryParams)
            : this.router.navigate(['./accounts/repeated-request'], queryParams);
        })
      ),
    { dispatch: false }
  );

  private subscriptions = new Subscription();

  constructor(
    private actions$: Actions,
    private router: Router
  ) {}
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
