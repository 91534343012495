import { AccountState, initialAccountState } from './account/account.state';
import { AccountUserState, initialAccountUserState } from './account-user/account-user.state';
import { FederationState, initialFederationState } from './federation/federation.state';
import { initialInvitationState, InvitationState } from './invitation/invitation.state';
import { initialRepositoryState, RepositoryState } from './repository/repository.state';
import { initialUserState, UserState } from './user/user.state';

export interface AppState {
  users: UserState;
  loading: boolean;
  accounts: AccountState;
  accountUsers: AccountUserState;
  invitations: InvitationState;
  repositories: RepositoryState;
  federations: FederationState;
}

export function initialAppState(): AppState {
  return {
    users: initialUserState,
    loading: false,
    accounts: initialAccountState,
    accountUsers: initialAccountUserState,
    invitations: initialInvitationState,
    repositories: initialRepositoryState,
    federations: initialFederationState
  };
}
