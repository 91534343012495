import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, ExperiencePrivilege, WorkroomPrivilege } from '@celum/authentication';
import { BatchDTO, BatchParams, getPaginationParams, InvitationStatus, SaccAccountUser } from '@celum/sacc/domain';

import { RestService } from '../services/rest.service';
import { Utils } from '../utils';

export interface AccountUserChanges {
  role?: AccountUserRole;
  status?: AccountAccessStatus;
  invitationStatus?: InvitationStatus;
  canKeepDriveScopes?: boolean;
  privileges?: {
    work?: WorkroomPrivilege;
    experience?: ExperiencePrivilege;
  };
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
}

export interface AccountUserUpdateRequest {
  statusUpdate?: {
    status: AccountAccessStatus;
    canKeepDriveScopes: boolean;
  };
  roleUpdate?: {
    role: AccountUserRole;
  };
  privilegeUpdate?: {
    work: WorkroomPrivilege;
    experience: ExperiencePrivilege;
  };
  groupMembershipUpdate?: {
    groupIdsToAdd?: string[];
    groupIdsToRemove?: string[];
  };
}

@Injectable({
  providedIn: 'root'
})
export class AccountUserResourceService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public updateAccountUserStatus(accountId: string, accountUser: SaccAccountUser, keepScopes: boolean): Observable<SaccAccountUser> {
    return this.httpClient
      .put(this.getApiUrl(`/accounts/${accountId}/users/${accountUser.id}/status`), {
        ...accountUser,
        keepScopes
      })
      .pipe(map(() => accountUser));
  }

  public updateAccountUserRole(accountId: string, accountUser: SaccAccountUser): Observable<SaccAccountUser> {
    return this.httpClient.put(this.getApiUrl(`/accounts/${accountId}/users/${accountUser.id}/role`), accountUser).pipe(map(() => accountUser));
  }

  public updateAccountUserServicePermissions(accountId: string, accountUser: SaccAccountUser): Observable<SaccAccountUser> {
    return this.httpClient.put(this.getApiUrl(`/accounts/${accountId}/users/${accountUser.id}/service-permissions`), accountUser).pipe(map(() => accountUser));
  }

  public removeAccountUser(accountId: string, accountUserId: string, keepScopes: boolean): Observable<string> {
    let url = this.getApiUrl(`/accounts/${accountId}/users/${accountUserId}`);
    if (keepScopes) {
      url = url.concat(`?keepScopes=${keepScopes}`);
    }
    return this.httpClient.delete(url).pipe(map(() => accountUserId));
  }

  public fetchBatch(accountId: string, batchParams: BatchParams = {}): Observable<BatchDTO<SaccAccountUser>> {
    return this.httpClient.get<BatchDTO<SaccAccountUser>>(this.getApiUrl(`/accounts/${accountId}/users`), {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public search(
    accountId: string,
    filter: string,
    invitationStatusIn: InvitationStatus[],
    statusIn: AccountAccessStatus[],
    batchParams: BatchParams = {}
  ): Observable<BatchDTO<SaccAccountUser>> {
    return this.httpClient.post<BatchDTO<SaccAccountUser>>(
      this.getApiUrl(`/accounts/${accountId}/members/.search`),
      { invitationStatusIn, ...(statusIn && { statusIn: statusIn }), nameOrEmailContains: filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: batchParams.count || this.batchSize
        })
      }
    );
  }

  public updateAccountUser(
    accountId: string,
    userId: string,
    changedValues: AccountUserChanges,
    accountUser?: SaccAccountUser
  ): Observable<SaccAccountUser | undefined> {
    const body: AccountUserUpdateRequest = this.prepareUpdateRequest(changedValues);

    return this.httpClient.patch<void>(this.getApiUrl(`/accounts/${accountId}/members/${userId}`), body).pipe(map(() => accountUser));
  }

  private prepareUpdateRequest(changedValues: AccountUserChanges): AccountUserUpdateRequest {
    const body: AccountUserUpdateRequest = {};

    if (changedValues.role) {
      body.roleUpdate = { role: changedValues.role };
    }

    if (changedValues.privileges?.experience || changedValues.privileges?.work) {
      body.privilegeUpdate = {
        ...(changedValues?.privileges?.work && { work: changedValues.privileges.work }),
        ...(changedValues?.privileges?.experience && {
          experience: changedValues.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : null
        })
      };
    }

    if (changedValues.status) {
      body.statusUpdate = {
        status: changedValues.status,
        canKeepDriveScopes: changedValues.canKeepDriveScopes || false
      };
    }

    if (changedValues?.groupIdsToAdd || changedValues?.groupIdsToRemove) {
      body.groupMembershipUpdate = {
        ...(changedValues.groupIdsToAdd && { groupIdsToAdd: changedValues.groupIdsToAdd }),
        ...(changedValues.groupIdsToRemove && { groupIdsToRemove: changedValues.groupIdsToRemove })
      };
    }

    return body;
  }
}
