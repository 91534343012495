import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Account, BatchDTO, BatchParams, getPaginationParams, UploadTicket, WorkRoomsPlanType } from '@celum/sacc/domain';

import { RestService } from './rest.service';
import { Utils } from '../utils';

/**
 * @deprecated
 * This service is deprecated and will be removed in the future. Use the new AccountService instead.
 */
@Injectable({ providedIn: 'root' })
export class LegacyAccountService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public exists(accountId: string, email: string): Observable<boolean> {
    return this.httpClient.post<any>(this.getApiUrl(`/accounts/${accountId}/users/exists`), { email }).pipe(map(r => r.exists));
  }

  // TODO: remove this once all the functionality is moved from the store to the new account service
  public get(id: string): Observable<Account> {
    return this.httpClient.get<Account>(this.getApiUrl(`/accounts/${id}`)).pipe(
      map(account => {
        account.licenses = [...account.licenses].sort((a, b) => a.type.localeCompare(b.type));
        return account;
      })
    );
  }

  public update(account: Account): Observable<Account> {
    return this.httpClient.patch<Account>(this.getApiUrl(`/accounts/${account.id}`), account);
  }

  public updateDetails(account: Account): Observable<Account> {
    return this.httpClient.patch<Account>(this.getApiUrl(`/accounts/${account.id}/companyDetails`), {
      ...account.companyDetails
    });
  }

  public create(account: Account): Observable<Account> {
    return this.httpClient.post<Account>(this.getApiUrl('/accounts'), account);
  }

  public getAccountLogoUploadTicket(accountId: string, fileSize: number): Observable<UploadTicket> {
    return this.httpClient.post<UploadTicket>(this.getApiUrl(`/accounts/${accountId}/uploadTickets`), { fileSize });
  }

  public confirmAccountLogoUpload(accountId: string, uploadTicket: UploadTicket): Observable<any> {
    return this.httpClient.post<any>(this.getApiUrl(`/accounts/${accountId}/uploadTickets/${uploadTicket.uploadTicketId}`), {});
  }

  public fetchBatch(batchParams: BatchParams = {}): Observable<BatchDTO<Account>> {
    return this.httpClient.get<BatchDTO<Account>>(this.getApiUrl('/accounts'), {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public search(filter: string, batchParams: BatchParams = {}): Observable<BatchDTO<Account>> {
    return this.httpClient.post<BatchDTO<Account>>(
      this.getApiUrl(`/accounts/search`),
      { filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public requestNewPlanForWorkRooms(accountId: string, plan: WorkRoomsPlanType): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/${accountId}/marketing/workrooms/plans`), { selectedPlan: plan });
  }

  public requestContentHubDemo(accountId: string): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/${accountId}/marketing/contenthub/demo`), {});
  }
}
