import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { AccountAccessStatus, UserState } from '@celum/authentication';
import { CelumAvatarModule, CelumEmptyPageModule, ColorConstants, EmptyPage, IconConfiguration } from '@celum/common-components';
import { CelumListModule } from '@celum/internal-components';
import { CelumDirectivesModule } from '@celum/ng2base';
import { AccountAccess, SaccAccountUser, UserGroup } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe } from '@celum/sacc/shared';

import { GroupFilterComponent } from './group-filter/group-filter.component';
import { GroupSelectorService } from './group-selector.service';
import { IconButtonComponent } from '../../icon-button/icon-button.component';
import { TableCountComponent } from '../../table-count/table-count.component';

@Component({
  selector: 'sacc-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
    TranslateModule,

    MatTooltipModule,

    CelumAvatarModule,
    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumListModule,

    GroupFilterComponent,
    GroupToAvatarConfigPipe,
    IconButtonComponent,
    TableCountComponent
  ],
  providers: [GroupSelectorService],
  // Needed to style the layout of the dialog content correctly
  encapsulation: ViewEncapsulation.None
})
export class GroupSelectorComponent implements OnChanges {
  @Input()
  public accountAccess: AccountAccess;

  @Input()
  public user: SaccAccountUser;

  @Output()
  public readonly addedGroupsChanged = new EventEmitter<UserGroup[]>();

  @Output()
  public readonly removedGroupsChanged = new EventEmitter<UserGroup[]>();

  protected noResultsConfig = EmptyPage.noActionConfig(
    'empty-groups-list',
    'no-group',
    'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.GROUP_SELECTOR.NO_GROUP_MEMBERSHIP',
    'small',
    148
  );

  protected readonly AccountAccessStatus = AccountAccessStatus;

  protected readonly icons = {
    experience: IconConfiguration.medium('logo-experience'),
    experienceInactive: IconConfiguration.medium('logo-experience-inactive'),
    work: IconConfiguration.medium('logo-workrooms'),
    workInactive: IconConfiguration.medium('logo-workrooms-inactive'),
    remove: new IconConfiguration('cancel-m').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20),
    removeDisabled: new IconConfiguration('cancel-m').withColor(ColorConstants.BLUE_GRAY_200).withIconSize(20),
    info: new IconConfiguration('info-icon').withIconSize(12)
  };

  protected readonly UserState = UserState;

  constructor(protected service: GroupSelectorService) {
    this.service
      .select(state => state.addedGroups)
      .pipe(takeUntilDestroyed())
      .subscribe(addedGroups => this.addedGroupsChanged.emit(addedGroups));

    this.service
      .select(state => state.removedGroups)
      .pipe(takeUntilDestroyed())
      .subscribe(removedGroups => this.removedGroupsChanged.emit(removedGroups));
  }

  public ngOnChanges({ user, accountAccess }: SimpleChanges): void {
    if (accountAccess && this.accountAccess) {
      this.service.patchState({ accountAccess: this.accountAccess });
    }

    if (user && this.user) {
      this.service.patchState({ user: this.user });
    }
  }

  protected trackByFn(_: number, item: UserGroup): string {
    return item.id;
  }
}
