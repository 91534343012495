import { createSelector } from '@ngrx/store';

import { userInvitationAdapter } from './invitation.state';
import { AppState } from '../app.state';

export const selectUserInvitationState = (state: AppState) => state.invitations;

export const selectInvitationUserInvitationsAll = createSelector(selectUserInvitationState, userInvitationAdapter.getSelectors().selectAll);

export const selectInvitationCount = createSelector(selectUserInvitationState, invitationState => invitationState.totalCount);

export const selectInvitationFilteredCount = createSelector(selectUserInvitationState, invitationState => invitationState.filterCount);

export const selectInvitationUserInvitationsAllLoaded = createSelector(
  selectUserInvitationState,
  selectInvitationUserInvitationsAll,
  (userInvitationState, allInvitations) => allInvitations.length > 0 && !userInvitationState.continuationToken
);

export const selectInvitationsLoading = createSelector(selectUserInvitationState, userInvitationState => userInvitationState.loading);

export const selectInvitationUserInvitationFilter = createSelector(selectUserInvitationState, accountUserState => accountUserState.filter);

export const selectInvitationUserInvitationNextBatchParams = createSelector(selectUserInvitationState, userInvitationState => {
  return {
    count: userInvitationAdapter.getSelectors().selectAll(userInvitationState).length,
    sort: userInvitationState.sort,
    continuationToken: userInvitationState.continuationToken
  };
});
