<div class="sacc-account-member-status-switcher">
  <div class="sacc-account-member-status-switcher_subcontainer">
    <span class="sacc-account-member-status-switcher_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.STATUS' | translate }}</span>
    @if (!config?.isInvitation) {
      <sacc-activation-status
        class="sacc-account-member-status-switcher_item"
        data-test-account-member-status-switcher-status
        [activationStatus]="config?.userOrInvitation.status"></sacc-activation-status>
    } @else if (config.isInvitation) {
      <sacc-invitation-status
        class="sacc-account-member-status-switcher_item"
        data-test-account-member-status-switcher-status
        [invitationStatus]="config?.userOrInvitation.invitationStatus"></sacc-invitation-status>
    }
  </div>
  @if (!statusSwitched()) {
    <div class="sacc-account-member-status-switcher_subcontainer">
      <span class="sacc-account-member-status-switcher_header">{{ 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.STATUS.ACTIONS' | translate }}</span>
      <sacc-icon-text-button
        class="sacc-account-member-status-switcher_action-button"
        data-test-account-member-status-switcher-status-button
        [disabled]="config?.disabled"
        [icon]="config?.buttonIcon"
        [label]="config?.buttonLabel"
        [matTooltip]="config?.buttonTooltip | translate"
        [size]="'medium'"
        (clicked)="onStatusSwitchClicked()"></sacc-icon-text-button>
    </div>
  } @else {
    <div class="sacc-account-member-status-switcher_subcontainer sacc-account-member-status-switcher_subcontainer-activate">
      <div class="sacc-account-member-status-switcher_activate-container" data-test-account-member-status-switcher-status-preview-activate>
        <span class="sacc-account-member-status-switcher_text-bold sacc-account-member-status-switcher_activate-header">
          {{ config?.switchedTextHeader | translate }}
        </span>
        <span class="sacc-account-member-status-switcher_text sacc-account-member-status-switcher_activate-text">
          {{ config?.switchedText | translate }}
        </span>
      </div>
    </div>
  }
</div>
