import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs/operators';

import { AccountAccess, AuthService, License, LicenseType, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { Account, SaccAccountUser } from '@celum/sacc/domain';
import {
  AccountResourceService,
  AppState,
  MemberShipTableColumns,
  selectAccountActiveAccountId,
  selectAccountUserFilter,
  selectAccountUserFilterCount,
  selectAccountUsersAll,
  selectAccountUsersAllLoaded,
  selectAccountUsersLoading,
  selectUserCanEditActiveAccount,
  selectUserLicenseByActiveAccountIdAndLicenseType
} from '@celum/sacc/shared';

type AccountUserTableState = {
  availableColumns: MemberShipTableColumns[];
  allAccountUsersLoaded: boolean;
  accountUsersLoading: boolean;
  hasExperienceLicense: boolean;
  hasWorkroomLicense: boolean;
  hasDriveLicense: boolean;
  canEdit: boolean;
  token: string;
  tokenExpiration: number;
  accountAccess: AccountAccess;
  accountId?: string;
  account?: Account;
};

export type AccountUserTableViewModel = {
  searchString: string;
  accountUsers?: AccountUserTableAccountUser[];
  displayedColumns: MemberShipTableColumns[];
  accountUserCount: number;
  currentUser: UserDetails;
} & Omit<AccountUserTableState, 'hasDriveLicense' | 'tokenExpiration' | 'availableColumns' | 'account'>;

export type AccountUserTableAccountUser = SaccAccountUser & { isSelf?: boolean; isOwner?: boolean; canEditRoles?: boolean };

@Injectable({ providedIn: 'root' })
export class AccountUserTableService extends ComponentStore<AccountUserTableState> {
  public vm$ = combineLatest([
    this.store$.select(selectAccountActiveAccountId),
    this.select(state => state.allAccountUsersLoaded),
    this.select(state => state.accountUsersLoading),
    this.store$.select(selectAccountUserFilter),
    this.store$.select(selectAccountUsersAll),
    this.select(state => state.account)
  ]).pipe(
    distinctUntilChanged(),
    withLatestFrom(
      this.store$.select(selectAccountUserFilterCount),
      this.select(state => state.canEdit),
      this.select(state => state.hasWorkroomLicense),
      this.select(state => state.hasExperienceLicense),
      this.select(state => state.token),
      this.userService.currentUser$.pipe(isTruthy())
    ),
    switchMap(
      ([
        [accountId, allAccountUsersLoaded, accountUsersLoading, searchString, accountUsers, account],
        accountUserCount,
        canEdit,
        hasWorkroomLicense,
        hasExperienceLicense,
        token,
        currentUser
      ]) => {
        return this.createViewModel(
          allAccountUsersLoaded,
          accountUsersLoading,
          searchString,
          accountUsers,
          accountUserCount,
          canEdit,
          hasWorkroomLicense,
          hasExperienceLicense,
          accountId,
          currentUser,
          token,
          account
        );
      }
    )
  );

  constructor(
    private store$: Store<AppState>,
    private authService: AuthService,
    private userService: UserService,
    private accountService: AccountResourceService
  ) {
    super({
      availableColumns: [],
      allAccountUsersLoaded: undefined,
      accountUsersLoading: undefined,
      hasDriveLicense: undefined,
      hasExperienceLicense: undefined,
      hasWorkroomLicense: undefined,
      canEdit: undefined,
      token: undefined,
      tokenExpiration: undefined,
      accountAccess: undefined
    });

    // Select and store values in the ComponentStore that the component (not the template) needs access to
    combineLatest([this.store$.select(selectAccountUsersAllLoaded), this.store$.select(selectAccountUsersLoading)])
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(
          this.store$.select(selectUserCanEditActiveAccount),
          this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.WORK)).pipe(map((license: License) => license && license.active)),
          this.store$
            .select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.EXPERIENCE))
            .pipe(map((license: License) => license && license.active)),
          this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.DRIVE)).pipe(map((license: License) => license && license.active)),
          this.select(state => state.token),
          this.userService.currentUser$.pipe(isTruthy()),
          this.store$.select(selectAccountActiveAccountId)
        ),
        switchMap(
          ([[allAccountUsersLoaded, accountUsersLoading], canEdit, hasWorkroomLicense, hasExperienceLicense, hasDriveLicense, token, user, accountId]) => {
            // Check whether we need to renew the token. We have to do this manually here because setting the link manually for avatars doesn't trigger any http interceptors,
            // so our normal token refresh mechanisms don't trigger here
            if (!token || this.get().tokenExpiration === undefined || Date.now() > this.get().tokenExpiration) {
              return this.authService.getAuthResult().pipe(
                tap(result =>
                  this.patchState({
                    tokenExpiration: result.expiresOn.getTime(),
                    token: result.idToken
                  })
                ),
                map(() => ({
                  allAccountUsersLoaded,
                  accountUsersLoading,
                  canEdit,
                  hasWorkroomLicense,
                  hasExperienceLicense,
                  hasDriveLicense,
                  accountAccess: user.accountAccesses.find(accountAccess => accountAccess.accountId === accountId),
                  accountId
                }))
              );
            }

            return of({
              allAccountUsersLoaded,
              accountUsersLoading,
              canEdit,
              hasWorkroomLicense,
              hasExperienceLicense,
              hasDriveLicense,
              accountAccess: user.accountAccesses.find(accountAccess => accountAccess.accountId === accountId),
              accountId
            });
          }
        ),
        takeUntilDestroyed()
      )
      .subscribe(data =>
        this.patchState({
          allAccountUsersLoaded: data.allAccountUsersLoaded,
          accountUsersLoading: data.accountUsersLoading,
          hasWorkroomLicense: data.hasWorkroomLicense,
          hasExperienceLicense: data.hasExperienceLicense,
          hasDriveLicense: data.hasDriveLicense,
          canEdit: data.canEdit,
          accountAccess: data.accountAccess,
          accountId: data.accountId
        })
      );

    // Fetch information about the currently selected account. Needed if this component is used in a context where the account is not yet loaded into the store
    this.state$
      .pipe(
        filter(vm => !!vm.accountId || vm.accountId !== vm.account?.id),
        map(vm => vm.accountId),
        distinctUntilChanged(),
        isTruthy(),
        switchMap(accountId => this.accountService.getById(accountId))
      )
      .subscribe(account => {
        this.patchState({ account });
      });
  }

  private createViewModel(
    allAccountUsersLoaded: boolean,
    accountUsersLoading: boolean,
    searchString: string,
    accountUsers: SaccAccountUser[],
    accountUserCount: number,
    canEdit: boolean,
    hasWorkroomLicense: boolean,
    hasExperienceLicense: boolean,
    accountId: string,
    currentUser: UserDetails,
    token: string,
    account: Account
  ): Observable<AccountUserTableViewModel> {
    let displayedColumns = this.get().availableColumns;
    if (!canEdit) {
      displayedColumns = displayedColumns.filter(column => column !== MemberShipTableColumns.Actions);
    }

    if (!account?.associatedFederations?.length) {
      displayedColumns = displayedColumns.filter(column => column !== MemberShipTableColumns.Imported);
    }

    return of({
      allAccountUsersLoaded,
      accountUsersLoading,
      searchString,
      accountUsers: accountUsers.map(user => {
        const isOwner = currentUser.accountAccesses.find(accountAccess => accountAccess.accountId === accountId)?.ownerOid === user.oid;
        const isSelf = user.oid === currentUser.oid;
        return {
          ...user,
          isSelf,
          isOwner,
          canEditRoles: currentUser.admin ? canEdit && !isOwner && !isSelf : canEdit
        };
      }),
      accountUserCount,
      canEdit,
      token,
      hasExperienceLicense,
      hasWorkroomLicense,
      displayedColumns,
      currentUser,
      accountAccess: currentUser.accountAccesses.find(accountAccess => accountAccess.accountId === accountId)
    });
  }
}
