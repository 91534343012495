import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { debounceTime, switchMap, tap } from 'rxjs';

import { tapAllResponses } from '@celum/core';
import { AccountAccess, SaccAccountUser, UserGroup } from '@celum/sacc/domain';
import { UserGroupResourceService } from '@celum/sacc/shared';
import { FilterState } from '@celum/shared/domain';
import { ErrorService } from '@celum/shared/util';

export type GroupFilterState = {
  accountAccess?: AccountAccess;
  groupLimit?: number;
  addedGroups?: UserGroup[];
  removedGroups?: UserGroup[];
  user?: SaccAccountUser;
} & FilterState<UserGroup>;

@Injectable()
export class GroupFilterService extends ComponentStore<GroupFilterState> {
  public vm$ = this.select(state => this.createViewModel(state));

  public loadGroupsForAccountAccess = this.effect<{ accountId: string; filter: string }>(load$ =>
    load$.pipe(
      tap(() => this.patchState({ loading: true })),
      switchMap(({ accountId, filter }) =>
        this.groupsService.searchUserGroupsForAccount(accountId, {
          filter
        })
      ),
      tapAllResponses(
        result => {
          this.patchState({
            filteredValues: result.data,
            loading: false
          });
        },
        error => {
          this.errorService.httpError(error as HttpErrorResponse, 'GroupFilterService');
          this.patchState({ filteredValues: [], loading: false });
        }
      )
    )
  );

  constructor(
    private groupsService: UserGroupResourceService,
    private errorService: ErrorService
  ) {
    super({
      selectedValues: [],
      searchValue: '',
      loading: false,
      filteredValues: null,
      isOpen: false,
      onTouched: null,
      onChange: null
    });

    this.select(state => state.searchValue)
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.loadGroupsForAccountAccess({ accountId: this.get().accountAccess.accountId, filter: this.get().searchValue });
      });
  }

  public toggleMenu(isOpen: boolean): void {
    this.patchState({ isOpen });
    if (isOpen) {
      this.loadGroupsForAccountAccess({ accountId: this.get().accountAccess.accountId, filter: this.get().searchValue });
      return;
    }
    this.patchState({ searchValue: '' });
  }

  private createViewModel(state: GroupFilterState): GroupFilterState & { data: UserGroup[] } {
    return {
      ...state,
      selectedValues: GroupFilterService.getSelectedValues(state.filteredValues, state.removedGroups, state.addedGroups, state.user),
      data: state.filteredValues
    };
  }

  private static getSelectedValues(filteredValues: UserGroup[], removedGroups: UserGroup[], addedGroups: UserGroup[], user?: SaccAccountUser): UserGroup[] {
    const groupsForUser = user ? filteredValues?.filter(group => user.groups.some(usersGroup => usersGroup.id === group.id)) : [];
    const usersWithoutRemoved = groupsForUser?.filter(group => !removedGroups.some(removedGroup => removedGroup.id === group.id));

    return usersWithoutRemoved ? [...usersWithoutRemoved, ...addedGroups] : [...addedGroups];
  }
}
