import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { federationActions } from './federation.action';
import { selectFederationFilter, selectFederationNextBatchParams } from './federation.selectors';
import { ErrorFactory } from '../../error.factory';
import { FederationResourceService } from '../../services/federation-resource.service';
import { AppState } from '../app.state';
import { loaderActions } from '../loader/loader.action';
import { notificationActions } from '../notification/notification.action';

@Injectable()
export class FederationEffects {
  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.register, federationActions.update),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.registerSuccess, federationActions.registerFailure, federationActions.updateSuccess, federationActions.updateFailure),
      map(() => loaderActions.hide())
    )
  );

  public onRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.register),
      mergeMap(action =>
        this.federationService.registerFederation(action.federation).pipe(
          map(federation => federationActions.registerSuccess({ federation })),
          catchError(error => of(federationActions.registerFailure({ error })))
        )
      )
    )
  );

  public onRegisterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.registerSuccess),
      map(() => notificationActions.info({ message: this.translateService.instant('SERVICES.FEDERATION.EFFECTS.REGISTRATION_SUCCESS_MSG') }))
    )
  );

  public onFetchBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.fetchBatch),
      withLatestFrom(this.store$.select(selectFederationNextBatchParams)),
      exhaustMap(([action, batchParams]) =>
        this.federationService.fetchBatch(batchParams, action.batchSize).pipe(
          map(batch => federationActions.fetchBatchSuccess({ batch })),
          catchError(error => of(federationActions.fetchBatchFailure({ error })))
        )
      )
    )
  );

  public onUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.update),
      switchMap(action =>
        this.federationService.update(action.federation).pipe(
          map(federation => federationActions.updateSuccess({ federation })),
          catchError(error => of(federationActions.updateFailure({ error })))
        )
      )
    )
  );

  public onUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.updateSuccess),
      map(() => notificationActions.info({ message: this.translateService.instant('SERVICES.FEDERATION.EFFECTS.UPDATE_SUCCESS_MSG') }))
    )
  );

  public onFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.updateFailure, federationActions.registerFailure, federationActions.deleteFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant(ErrorFactory.getErrorMessage(action.error, this.translateService))
        })
      )
    )
  );

  public fetchBatchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.fetchBatchFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.FEDERATION.EFFECTS.FETCH_FEDERATIONS_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        federationActions.resetFederationTable,
        federationActions.filterChanged,
        federationActions.registerSuccess,
        federationActions.updateSuccess,
        federationActions.deleteSuccess
      ),
      withLatestFrom(this.store$.select(selectFederationFilter)),
      map(([_, searchString]) => federationActions.search({ filter: searchString }))
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.search),
      withLatestFrom(this.store$.select(selectFederationNextBatchParams)),
      switchMap(([action, batchParams]) =>
        this.federationService.search(action.filter, batchParams).pipe(
          map(batch => federationActions.searchSuccess({ batch })),
          catchError(error => of(federationActions.searchFailure({ error })))
        )
      )
    )
  );

  public onDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.delete),
      mergeMap(action =>
        this.federationService.deleteFederation(action.federationId).pipe(
          map(federationId => federationActions.deleteSuccess({ federationId })),
          catchError(error => of(federationActions.deleteFailure({ error })))
        )
      )
    )
  );

  public onDeleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(federationActions.deleteSuccess),
      map(() => notificationActions.info({ message: this.translateService.instant('SERVICES.FEDERATION.EFFECTS.DELETE_SUCCESS_MSG') }))
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private federationService: FederationResourceService
  ) {}
}
