import { createReducer, on } from '@ngrx/store';

import { loaderActions } from './loader.action';

export const initialUserState = false;

export const loaderReducer = createReducer(
  initialUserState,
  on(loaderActions.show, () => true),
  on(loaderActions.hide, () => false)
);
