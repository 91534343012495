@if (service.vm$ | async; as vm) {
  @if (!vm.loading) {
    @if (vm.totalElementCount > 0) {
      <div class="sacc-user-groups-list_count">
        <sacc-table-count
          [count]="vm.totalElementCount"
          [label]="'COMPONENTS.USER_GROUPS.LIST.COUNT'"
          [labels]="'COMPONENTS.USER_GROUPS.LIST.COUNTS'"></sacc-table-count>
      </div>
    }
    @if (vm.totalElementCount >= groupCountLimit) {
      <message-box
        class="sacc-user-groups-list_limit-reached"
        [text]="'COMPONENTS.USER_GROUPS.LIST.LIMIT_REACHED' | translate: { groupLimit: groupCountLimit }"
        [type]="'warn'"></message-box>
    }
    <div class="sacc-user-groups-list_table">
      @if (vm.totalElementCount === 0) {
        <empty-page class="sacc-user-groups-list_table_empty-page" [config]="noResultsConfig"></empty-page>
      }
      @if (vm.totalElementCount > 0) {
        <table aria-label="User groups table" mat-table [dataSource]="vm.userGroups">
          <ng-container matColumnDef="groupAvatar">
            <th *matHeaderCellDef mat-header-cell>&nbsp;</th>
            <td *matCellDef="let element; let i = index" mat-cell>
              <sacc-group-avatar [config]="element | groupToAvatarConfig"></sacc-group-avatar>
            </td>
          </ng-container>
          <ng-container matColumnDef="groupName">
            <th *matHeaderCellDef mat-header-cell>{{ 'COMPONENTS.USER_GROUPS.LIST.HEADERS.NAME' | translate }}</th>
            <td *matCellDef="let element" mat-cell>{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="imported">
            <th *matHeaderCellDef="let element" id="imported-header" mat-header-cell>{{ 'COMPONENTS.ACCOUNT_USER_TABLE.IMPORTED' | translate }}</th>
            <td *matCellDef="let element" mat-cell [ngClass]="element.status">
              @if (element.imported) {
                <celum-icon [configuration]="icons.imported" [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_TOOLTIP' | translate"></celum-icon>
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef id="actions-header" mat-header-cell>&nbsp;</th>
            <td *matCellDef="let element" mat-cell>
              @if (vm.activeAccountAccess?.role === AccountUserRole.MANAGER && !vm.isReadonly) {
                <sacc-icon-button
                  [iconConfiguration]="icons.menu"
                  [matMenuTriggerFor]="menu"
                  [matTooltip]="'COMPONENTS.TABLE.TOOLTIPS.MORE_ACTIONS' | translate"
                  (click)="$event.stopPropagation()"></sacc-icon-button>
              }
              <mat-menu #menu="matMenu">
                <ng-container>
                  <button
                    [disabled]="element.imported"
                    [matTooltip]="'COMPONENTS.USER_GROUPS.LIST.IMPORTED_DELETE_DISABLED_TOOLTIP' | translate"
                    [matTooltipDisabled]="!element.imported"
                    mat-menu-item
                    (click)="deleteGroup(element)">
                    <celum-icon [configuration]="icons.delete"></celum-icon>
                    <span>{{ 'COMPONENTS.USER_GROUPS.LIST.DELETE_GROUP' | translate }}</span>
                  </button>
                </ng-container>
              </mat-menu>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="vm.displayedColumns; sticky: true" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: vm.displayedColumns; let index = index"
            class="sacc-user-groups-list_table-row"
            mat-row
            (click)="
              openGroupDialog(
                row,
                vm.activeAccountAccess,
                vm.activeAccountAccess?.role !== AccountUserRole.MANAGER || vm.isReadonly || row.imported,
                vm.accountId
              )
            "></tr>
        </table>
        <span inViewport (visible)="$event && service.loadNextBatch()">&nbsp;</span>
      }
    </div>
  }
  <div class="sacc-user-groups-list_spinner">
    @if (vm.loading || vm.loadingBatch) {
      <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
    }
  </div>
}
