import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { BatchDTO, Federation } from '@celum/sacc/domain';

export const federationActions = createActionGroup({
  source: '[Federation]',
  events: {
    'Get One': props<{ federationId: string }>(),
    'Get One Success': props<{ federation: Federation }>(),
    'Get One Failure': props<{ error: any }>(),
    // prettier-ignore
    'Register': props<{ federation: Federation }>(),
    'Register Success': props<{ federation: Federation }>(),
    'Register Failure': props<{ error: any }>(),
    // prettier-ignore
    'Update': props<{ federation: Federation }>(),
    'Update Success': props<{ federation: Federation }>(),
    'Update Failure': props<{ error: any }>(),
    // prettier-ignore
    'Delete': props<{ federationId: string }>(),
    'Delete Success': props<{ federationId: string }>(),
    'Delete Failure': props<{ error: any }>(),
    'Fetch Batch': props<{ batchSize: number }>(),
    'Fetch Batch Success': props<{ batch: BatchDTO<Federation> }>(),
    'Fetch Batch Failure': props<{ error: any }>(),
    // prettier-ignore
    'Search': props<{ filter: string }>(),
    'Search Success': props<{ batch: BatchDTO<Federation> }>(),
    'Search Failure': props<{ error: any }>(),
    'Filter Changed': props<{ filter: string }>(),
    'Reset Federation Table': emptyProps(),
    'Reset Federation Table Filter': emptyProps(),
    'Request Safe Redirect': props<{ url: string; accountId?: string }>()
  }
});
