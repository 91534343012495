import { AsyncPipe } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { take } from 'rxjs/operators';

import { AccountAccessStatus, ExperiencePrivilege, InvitationStatus, UserState, WorkroomPrivilege } from '@celum/authentication';
import { AVATAR_SIZE, CelumAvatarModule, CelumDialogModule, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { CelumListModule } from '@celum/internal-components';
import { CelumDirectivesModule } from '@celum/ng2base';
import { AccountAccess, SaccAccountUser } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe } from '@celum/sacc/shared';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { UserNamePipe } from '@celum/shared/util';

import { EditInvitationDialogService } from './edit-invitation-dialog.service';
import { ActivationStatusComponent } from '../../activation-status/activation-status.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { IconButtonComponent } from '../../icon-button/icon-button.component';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { TableCountComponent } from '../../table-count/table-count.component';
import { AccountUserStatusSwitcherComponent } from '../account-user-status-switcher/account-user-status-switcher.component';
import { GroupSelectorComponent } from '../group-selector/group-selector.component';

export interface EditInvitationDialogData {
  editedInvitation: SaccAccountUser;
  accountAccess: AccountAccess;
  currentUser: SaccAccountUser;
}

@Component({
  selector: 'sacc-edit-invitation-dialog',
  templateUrl: './edit-invitation-dialog.component.html',
  styleUrls: ['./edit-invitation-dialog.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,

    CelumAvatarModule,
    CelumDialogModule,
    CelumDirectivesModule,
    CelumListModule,

    AccountUserStatusSwitcherComponent,
    ActivationStatusComponent,
    AvatarComponent,
    GroupSelectorComponent,
    GroupToAvatarConfigPipe,
    IconButtonComponent,
    IconTextButtonComponent,
    TableCountComponent,
    UserAvatarComponent,
    UserNamePipe
  ],
  // Needed to style the layout of the dialog content correctly
  encapsulation: ViewEncapsulation.None,
  providers: [EditInvitationDialogService]
})
export class EditInvitationDialogComponent {
  protected AVATAR_SIZE = AVATAR_SIZE;
  protected invitationForm = this.formBuilder.nonNullable.group({
    // TODO: Rewrite with https://celum.atlassian.net/browse/SACC-3163
    /* experience: this.formBuilder.nonNullable.control<boolean>(
                                                                   this.data.editedInvitation.experiencePermissions.some(permission => permission === ExperiencePermissions.FULL_ACCESS)
                                                                   ),*/
    status: this.formBuilder.nonNullable.control<InvitationStatus>(this.data.editedInvitation.invitationStatus),
    privileges: this.formBuilder.nonNullable.group({
      work: this.formBuilder.nonNullable.control<WorkroomPrivilege>(this.data.editedInvitation.privileges.work),
      experience: this.formBuilder.nonNullable.control<ExperiencePrivilege>(this.data.editedInvitation.privileges.experience)
    })
    // TODO: Rewrite with https://celum.atlassian.net/browse/SACC-3163
    /*permissions: this.formBuilder.nonNullable.group({
                                                                   createWorkrooms: this.formBuilder.nonNullable.control<boolean>(
                                                                   this.data.editedInvitation.workroomPermissions.some(permission => permission === WorkroomPermission.CREATE_WORKROOMS)
                                                                   ),
                                                                   finishWorkrooms: this.formBuilder.nonNullable.control<boolean>(
                                                                   this.data.editedInvitation.workroomPermissions.some(permission => permission === WorkroomPermission.FINISH_WORKROOMS)
                                                                   ),
                                                                   manageTemplates: this.formBuilder.nonNullable.control<boolean>(
                                                                   this.data.editedInvitation.workroomPermissions.some(permission => permission === WorkroomPermission.MANAGE_TEMPLATES)
                                                                   )
                                                                   })*/
  });

  protected readonly icons = {
    experience: IconConfiguration.medium('logo-experience'),
    experienceInactive: IconConfiguration.medium('logo-experience-inactive'),
    work: IconConfiguration.medium('logo-workrooms'),
    workInactive: IconConfiguration.medium('logo-workrooms-inactive'),
    info: new IconConfiguration('info-icon').withIconSize(12)
  };

  protected readonly AccountAccessStatus = AccountAccessStatus;

  protected readonly UserState = UserState;

  constructor(
    private formBuilder: FormBuilder,
    protected service: EditInvitationDialogService,
    protected dialogRef: MatDialogRef<EditInvitationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: EditInvitationDialogData
  ) {
    this.service.init(data.editedInvitation, data.accountAccess, data.currentUser);

    this.invitationForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (DataUtil.isEmpty(this.service.getChangedValues(this.invitationForm.value, this.data.editedInvitation))) {
        this.invitationForm.markAsPristine();
      }

      this.service.patchState({ currentStatus: this.invitationForm.controls.status.value });
    });
  }

  // TODO @CST: Implement correct call to backend
  protected updateInvitation(): void {
    const changes = this.service.getChangedValues(this.invitationForm.value, this.data.editedInvitation);
    console.log('ch-ch-ch-ch-changes', changes);
  }

  protected onStatusSwitcherClicked(): void {
    this.service.vm$.pipe(take(1)).subscribe(vm => {
      const currentStatus = this.invitationForm.controls.status.value;
      if (vm.editedInvitation.invitationStatus === InvitationStatus.INVITED && currentStatus === InvitationStatus.INVITED) {
        this.service.patchState({ resendInvitation: true });
        this.invitationForm.markAsDirty();
      }
    });
  }
}
