export enum InvitationStatus {
  /** For email based invitation. The user has to accept the invitation to become a member. */
  INVITED = 'INVITED',
  /** The user accepted a personal (email based) invitation. */
  ACCEPTED = 'ACCEPTED',
  /** The user rejected a personal (email based) invitation. */
  REJECTED = 'REJECTED',
  /** For shared link based invitation requests. One of the account managers has to approve the invitation for the user to become a member. */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /** An account manager approved the link based invitation request. */
  APPROVED = 'APPROVED',
  /** An account manager rejected the link based invitation request. */
  DISAPPROVED = 'DISAPPROVED'
}
