import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable, of, throwError } from 'rxjs';
import { catchError, map, reduce } from 'rxjs/operators';

import { ExperiencePrivilege, InvitationStatus, WorkroomPrivilege } from '@celum/authentication';
import { BatchDTO, BatchParams, BulkResultDTO, getPaginationParams, SaccAccountUser } from '@celum/sacc/domain';

import { RestService } from '../services/rest.service';
import { Utils } from '../utils';

export interface InvitationChanges {
  invitationStatus?: InvitationStatus;
  privileges?: {
    work?: WorkroomPrivilege;
    experience?: ExperiencePrivilege;
  };
  groupIdsToAdd?: string[];
  groupIdsToRemove?: string[];
  resendInvitation?: boolean;
}

export interface InvitationUpdateRequest {
  statusUpdate?: {
    status: InvitationStatus;
  };
  privilegeUpdate?: {
    work?: WorkroomPrivilege;
    experience?: ExperiencePrivilege;
  };
  groupMembershipUpdate?: {
    groupIdsToAdd?: string[];
    groupIdsToRemove?: string[];
  };
  resendInvitation?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class InvitationResourceService extends RestService {
  private accountRecordHeight = 48;
  private readonly batchSize = Utils.calculateBatchSize(this.accountRecordHeight);

  constructor(private httpClient: HttpClient) {
    super();
  }

  public fetchBatch(accountId: string, batchParams: BatchParams = {}): Observable<BatchDTO<SaccAccountUser>> {
    return this.httpClient.get<BatchDTO<SaccAccountUser>>(this.getApiUrl(`/accounts/${accountId}/invitations`), {
      params: getPaginationParams({
        ...batchParams,
        count: this.batchSize
      })
    });
  }

  public search(accountId: string, filter: string, batchParams: BatchParams = {}): Observable<BatchDTO<SaccAccountUser>> {
    return this.httpClient.post<BatchDTO<SaccAccountUser>>(
      this.getApiUrl(`/accounts/${accountId}/invitations/search`),
      { filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public searchNew(
    accountId: string,
    invitationStatusIn: InvitationStatus[],
    filter: string,
    batchParams: BatchParams = {}
  ): Observable<BatchDTO<SaccAccountUser>> {
    return this.httpClient.post<BatchDTO<SaccAccountUser>>(
      this.getApiUrl(`/accounts/${accountId}/members/.search`),
      { invitationStatusIn, nameOrEmailContains: filter },
      {
        params: getPaginationParams({
          ...batchParams,
          count: this.batchSize
        })
      }
    );
  }

  public inviteUsers(accountId: string, emails: string[]): Observable<BulkResultDTO<SaccAccountUser, any>> {
    const bulkResult: BulkResultDTO<SaccAccountUser, any> = {
      successful: [],
      failed: []
    };

    const obs = emails.map(email =>
      this.httpClient.post(this.getApiUrl(`/accounts/${accountId}/invitations`), { email }).pipe(
        map(json => json as SaccAccountUser),
        catchError(error => of({ email, error }))
      )
    );

    return concat(...obs).pipe(
      reduce((acc, curr) => {
        if ((curr as any).invitationStatus !== undefined) {
          acc.successful.push(curr as SaccAccountUser);
        } else {
          acc.failed.push(curr);
        }

        return acc;
      }, bulkResult)
    );
  }

  public updateInvitation(
    accountId: string,
    userId: string,
    changedValues: InvitationChanges,
    invitation?: SaccAccountUser
  ): Observable<SaccAccountUser | undefined> {
    const body: InvitationUpdateRequest = this.prepareUpdateRequest(changedValues);

    return this.httpClient.patch<void>(this.getApiUrl(`/accounts/${accountId}/members/${userId}`), body).pipe(map(() => invitation));
  }

  public acceptInvitation(accountId: string): Observable<string> {
    return this.httpClient.post(this.getApiUrl(`/accounts/${accountId}/invitations/current-user/accept`), {}).pipe(map(() => accountId));
  }

  public resendInvitation(accountId: string, userInvitationId: string): Observable<SaccAccountUser> {
    return this.httpClient.put<SaccAccountUser>(this.getApiUrl(`/accounts/${accountId}/invitations/${userInvitationId}`), {});
  }

  public rejectInvitation(accountId: string): Observable<string> {
    return this.httpClient.put(this.getApiUrl(`/accounts/${accountId}/invitations/current-user/reject`), {}).pipe(map(() => accountId));
  }

  public removeUserInvitation(accountId: string, userInvitationId: string, invitationStatus: InvitationStatus): Observable<string> {
    return this.httpClient.delete(this.getApiUrl(`/accounts/${accountId}/invitations/${userInvitationId}`)).pipe(
      map(() => userInvitationId),
      catchError(error => {
        error.invitationStatus = invitationStatus;
        return throwError(() => error);
      })
    );
  }

  public approveAccountAccess({ id, accountId }: SaccAccountUser): Observable<{ accountUser: SaccAccountUser; id: string }> {
    return this.httpClient.post<SaccAccountUser>(this.getApiUrl(`/accounts/${accountId}/accesses/${id}/approve`), {}).pipe(
      map(accountAccess => {
        return {
          accountUser: accountAccess,
          id
        };
      })
    );
  }

  public disapproveAccountAccess({ id, accountId }: SaccAccountUser): Observable<SaccAccountUser> {
    return this.httpClient.put<SaccAccountUser>(this.getApiUrl(`/accounts/${accountId}/accesses/${id}/disapprove`), {});
  }

  public exists(accountId: string, email: string): Observable<boolean> {
    return this.httpClient.post<{ exists: boolean }>(this.getApiUrl(`/accounts/${accountId}/invitations/exists`), { email }).pipe(map(r => r.exists));
  }

  public requestAccountAccess(repositoryId: string, accountId: string): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`/accounts/access`), {
      repositoryId,
      accountId
    });
  }

  private prepareUpdateRequest(changedValues: InvitationChanges): InvitationUpdateRequest {
    const body: InvitationUpdateRequest = {};

    if (changedValues.privileges?.experience || changedValues.privileges?.work) {
      body.privilegeUpdate = {
        ...(changedValues?.privileges.experience && { experience: changedValues.privileges.experience }),
        ...(changedValues?.privileges.work && { work: changedValues.privileges.work })
      };
    }

    if (changedValues.invitationStatus) {
      body.statusUpdate = {
        status: changedValues.invitationStatus
      };
    }

    if (changedValues?.groupIdsToAdd || changedValues?.groupIdsToRemove) {
      body.groupMembershipUpdate = {
        ...(changedValues.groupIdsToAdd && { groupIdsToAdd: changedValues.groupIdsToAdd }),
        ...(changedValues.groupIdsToRemove && { groupIdsToRemove: changedValues.groupIdsToRemove })
      };
    }

    if (changedValues?.resendInvitation) {
      body.resendInvitation = true;
    }

    return body;
  }
}
