import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';

import { AccountAccess, InvitationStatus, UserDetails, UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { SaccAccountUser } from '@celum/sacc/domain';
import {
  AppState,
  MemberShipTableColumns,
  selectAccountActiveAccountId,
  selectInvitationFilteredCount,
  selectInvitationsLoading,
  selectInvitationUserInvitationFilter,
  selectInvitationUserInvitationsAll,
  selectInvitationUserInvitationsAllLoaded,
  selectUserCanEditActiveAccount
} from '@celum/sacc/shared';

export type InvitationTableState = {
  searchString: string;
  allUserInvitationsLoaded: boolean;
  userInvitationsLoading: boolean;
  availableColumns: MemberShipTableColumns[];
};

export type InvitationTableViewModel = {
  invitations: ExtendedSaccAccountUser[];
  invitationCount: number;
  canEdit: boolean;
  displayedColumns: MemberShipTableColumns[];
  currentUser: UserDetails;
  accountAccess: AccountAccess;
} & InvitationTableState;

export type ExtendedSaccAccountUser = SaccAccountUser & {
  canBeDeleted: boolean;
  canBeRejected: boolean;
  canBeAccepted: boolean;
  canBeResent: boolean;
};

@Injectable()
export class InvitationTableService extends ComponentStore<InvitationTableState> {
  public vm$ = this.createViewModel();

  constructor(
    private store$: Store<AppState>,
    private userService: UserService
  ) {
    super({ availableColumns: [], searchString: undefined, allUserInvitationsLoaded: undefined, userInvitationsLoading: undefined });

    // Select and store values in the ComponentStore that the component (not the template) needs access to
    combineLatest([
      this.store$.select(selectInvitationUserInvitationsAllLoaded),
      this.store$.select(selectInvitationsLoading),
      this.store$.select(selectInvitationUserInvitationFilter)
    ])
      .pipe(takeUntilDestroyed())
      .subscribe(([allUserInvitationsLoaded, userInvitationsLoading, searchString]) =>
        this.patchState({ allUserInvitationsLoaded, userInvitationsLoading, searchString })
      );
  }

  public get filter(): string {
    return this.get().searchString;
  }

  public get allUserInvitationsLoaded(): boolean {
    return this.get().allUserInvitationsLoaded;
  }

  public get userInvitationsLoading(): boolean {
    return this.get().userInvitationsLoading;
  }

  public init(availableColumns: MemberShipTableColumns[]): void {
    this.patchState({ availableColumns });
  }

  private createViewModel(): Observable<InvitationTableViewModel> {
    const invitations$ = this.store$.select(selectInvitationUserInvitationsAll);
    const filteredInvitationCount$ = this.store$.select(selectInvitationFilteredCount);
    const canEdit$ = this.store$.select(selectUserCanEditActiveAccount);
    const accountId$ = this.store$.select(selectAccountActiveAccountId);
    const currentUser$ = this.userService.currentUser$.pipe(isTruthy());
    return this.select(
      this.state$,
      invitations$,
      filteredInvitationCount$,
      canEdit$,
      currentUser$,
      accountId$,
      (state, invitations, invitationCount, canEdit, currentUser, accountId) => ({
        ...state,
        invitations: invitations.map(invitation => ({
          ...invitation,
          canBeDeleted: [InvitationStatus.PENDING_APPROVAL, InvitationStatus.INVITED, InvitationStatus.DISAPPROVED, InvitationStatus.REJECTED].includes(
            invitation.invitationStatus
          ),
          canBeRejected: invitation.invitationStatus === InvitationStatus.PENDING_APPROVAL,
          canBeAccepted: invitation.invitationStatus !== InvitationStatus.INVITED && invitation.invitationStatus !== InvitationStatus.REJECTED,
          canBeResent: [InvitationStatus.INVITED, InvitationStatus.REJECTED].includes(invitation.invitationStatus),
          canBeModified: invitation.invitationStatus === InvitationStatus.INVITED
        })),
        invitationCount,
        allInvitationsLoaded: state.allUserInvitationsLoaded,
        displayedColumns: state.availableColumns,
        canEdit,
        accountAccess: currentUser.accountAccesses.find(access => access.accountId === accountId),
        currentUser
      })
    );
  }
}
