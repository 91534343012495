import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { authGuardWithLicenceCheck, LicenseType } from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';
import { AccountResolver } from '@celum/sacc/shared';

import { AccountGuard } from './guards/account.guard';
import { AdminGuard } from './guards/admin.guard';
import { hasAccessToAccountGuard } from './guards/has-access-to-account.guard';
import { hasActiveAccountsGuard } from './guards/has-active-accounts.guard';
import { QueryParamGuard } from './guards/query-param.guard';
import { userGroupGuard } from './guards/user-group.guard';
import { LandingPageComponent } from './landing-page/landing-page.component';

const routes: Routes = [
  { path: 'logout', loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent) },
  {
    path: 'accounts/first-request',
    loadComponent: () => import('@celum/sacc/features/accounts').then(mod => mod.FirstPendingAccountAccessRequestPageComponent),
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.NO_LICENSE, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage)
    ]
  },
  {
    path: 'accounts/repeated-request',
    loadComponent: () => import('@celum/sacc/features/accounts').then(mod => mod.RepeatedPendingAccountAccessRequestPageComponent),
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.NO_LICENSE, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage)
    ]
  },
  {
    path: 'repository/:id/accounts',
    loadComponent: () => import('@celum/sacc/features/repositories').then(mod => mod.RepositoryAssociatedAccountPageComponent),
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.NO_LICENSE, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage)
    ]
  },
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.NO_LICENSE, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage),
      QueryParamGuard
    ],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadComponent: () => import('@celum/sacc/features/home').then(mod => mod.HomeComponent)
      },
      {
        path: 'myaccount',
        loadComponent: () => import('@celum/sacc/features/my-account').then(mod => mod.AccountFormPageComponent),
        canActivate: [AccountGuard]
      },
      {
        path: 'myaccount/:id',
        loadComponent: () => import('@celum/sacc/features/my-account').then(mod => mod.AccountFormPageComponent),
        canActivate: [AccountGuard]
      },
      {
        path: 'accounts',
        redirectTo: 'accounts/approved'
      },
      {
        path: 'accounts/approved',
        loadComponent: () => import('@celum/sacc/features/accounts').then(mod => mod.AccountAdministrationPageComponent),
        canActivate: [AdminGuard]
      },
      {
        path: 'accounts/:id',
        loadComponent: () => import('@celum/sacc/features/my-account').then(mod => mod.AccountAdminEditPageComponent),
        canActivate: [AdminGuard]
      },
      {
        path: 'accounts/:id/users',
        resolve: { account: AccountResolver },
        redirectTo: 'accounts/:id/users/members'
      },
      {
        path: 'accounts/:id/users/:tab',
        loadComponent: () => import('@celum/sacc/features/accounts').then(mod => mod.AccountUserAdministrationPageComponent),
        resolve: { account: AccountResolver },
        canActivate: [AdminGuard]
      },
      {
        path: 'accounts/:id/groups',
        loadComponent: () => import('@celum/sacc/features/accounts').then(mod => mod.AccountGroupAdministrationPageComponent),
        resolve: { account: AccountResolver },
        canActivate: [AdminGuard]
      },
      {
        path: 'account-membership',
        canActivate: [() => hasActiveAccountsGuard()],
        children: [
          {
            path: '',
            loadComponent: () => import('@celum/sacc/features/account-membership').then(mod => mod.AccountMembershipPageComponent)
          },
          {
            path: ':id',
            redirectTo: ':id/members'
          },
          {
            path: ':id/:tab',
            loadComponent: () => import('@celum/sacc/features/account-membership').then(mod => mod.AccountMembershipPageComponent),
            canActivate: [hasAccessToAccountGuard]
          }
        ]
      },
      {
        path: 'repositories',
        loadComponent: () => import('@celum/sacc/features/repositories').then(mod => mod.RepositoryManagementPageComponent),
        canActivate: [AdminGuard]
      },
      {
        path: 'federations',
        canActivate: [AdminGuard],
        children: [
          {
            path: '',
            loadComponent: () => import('@celum/sacc/features/federations').then(mod => mod.FederationManagementPageComponent)
          },
          {
            path: ':id',
            redirectTo: ':id/users'
          },
          {
            path: ':id/:tab',
            loadComponent: () => import('@celum/sacc/features/federations').then(mod => mod.UsersAndGroupsComponent)
          }
        ]
      },
      {
        path: 'user-groups',
        loadComponent: () => import('@celum/sacc/features/user-groups').then(mod => mod.UserGroupsPageComponent),
        canActivate: [() => userGroupGuard()]
      },
      {
        path: 'user-groups/:id',
        loadComponent: () => import('@celum/sacc/features/user-groups').then(mod => mod.UserGroupsPageComponent),
        canActivate: [() => userGroupGuard(), hasAccessToAccountGuard]
      }
    ]
  },
  // Fallback route, has to be last
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
