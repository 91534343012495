import { createSelector } from '@ngrx/store';

import { AccountAccess, AccountAccessStatus, AccountUserRole, ContentHubLicense, isContentHubLicense, License, UserDetails } from '@celum/authentication';

import { TypeUtil } from '../../type.util';
import { selectAccountActiveAccountId } from '../account/account.selectors';
import { AppState } from '../app.state';

export const selectUserState = (state: AppState) => state.users;

export const selectUserCurrent = createSelector(selectUserState, userState => userState.currentUser);

export const selectUserCurrentEmail = createSelector(selectUserCurrent, currentUser => currentUser && currentUser.email);

export const selectUserCurrentName = createSelector(selectUserCurrent, currentUser => (currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : ''));

export const selectUserCurrentLocale = createSelector(selectUserCurrent, currentUser => currentUser && currentUser.locale);

export const selectUserAllAccountAccesses = createSelector(
  selectUserCurrent,
  currentUser => (currentUser && currentUser.accountAccesses) || ([] as AccountAccess[])
);

export const selectUserAllUserInvitations = createSelector(
  selectUserCurrent,
  (currentUser: UserDetails, props: any) =>
    (currentUser && currentUser.invitations.filter(invitation => props.invitationStatus === invitation.invitationStatus)) || []
);

export const selectUserIsAccountManager = createSelector(selectUserAllAccountAccesses, accountAccesses => accountAccesses.some(isActiveManager));

export const selectUserActiveAccountAccesses = createSelector(selectUserAllAccountAccesses, accountAccessList =>
  accountAccessList.filter(accountAccess => accountAccess.status === AccountAccessStatus.ACTIVE)
);

export const selectUserAllManagedAccountAccesses = createSelector(selectUserAllAccountAccesses, accountAccessList => accountAccessList.filter(isActiveManager));

export const selectUserIsAccountOwner = createSelector(selectUserCurrentEmail, selectUserAllAccountAccesses, (email, accountAccesses) =>
  accountAccesses.some(accAccess => accountOwner(email, accAccess))
);

export const selectUserHasCurrentUserAccountAccess = createSelector(
  selectUserAllAccountAccesses,
  (accountAccesses: AccountAccess[], props: Partial<AccountAccess>) => accountAccesses.some(accAccess => accAccess.accountId === props.accountId)
);

export const selectUserHasCurrentUserRepositoryAssociatedAccountAccess = createSelector(
  selectUserAllAccountAccesses,
  (accountAccesses: AccountAccess[], props: any) => {
    const accountAccess: AccountAccess = accountAccesses.find((accAccess: AccountAccess) => accAccess.accountId === props.accountId);

    if (!accountAccess || !accountAccess.licenses || !props.repositoryId) {
      return false;
    }

    return accountAccess.licenses
      .filter(isContentHubLicense)
      .some((chLicense: ContentHubLicense) => chLicense.chRepositories.some(chRepository => chRepository.repositoryId === props.repositoryId));
  }
);

export const selectUserAccountAccessByActiveAccountId = createSelector(
  selectUserAllAccountAccesses,
  selectAccountActiveAccountId,
  (accountAccesses, activeAccId) => accountAccesses.find(accAccess => accAccess.accountId === activeAccId)
);

export const selectUserIsAccountManagerOfActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, accountAccess =>
  isActiveManager(accountAccess)
);

export const selectUserIsAccountOwnerOfActiveAccount = createSelector(selectUserCurrent, selectUserAccountAccessByActiveAccountId, (user, access) =>
  accountOwner(user.email, access)
);

export const selectUserAllAccountAccessesByActiveLicenseType = createSelector(
  selectUserAllAccountAccesses,
  (accountAccesses: AccountAccess[], props: any) =>
    accountAccesses.filter(
      accountAccess => accountAccess.licenses && accountAccess.licenses.some(license => license.active && license.type === props.licenseType)
    ) || []
);

export const selectUserAllAccountAccessesByActiveLicenseTypeAndAccountUserStatus = createSelector(
  selectUserAllAccountAccessesByActiveLicenseType,
  (accountAccesses, props) => accountAccesses.filter(accountAccess => accountAccess.status === props.status) || []
);

export const selectUserAllLicensesByActiveAccountId = createSelector(selectUserAccountAccessByActiveAccountId, accountAccess =>
  accountAccess ? accountAccess.licenses : []
);

export const selectUserLicenseByActiveAccountIdAndLicenseType = (licenseType: string) =>
  createSelector(selectUserAllLicensesByActiveAccountId, (licenses: License[]) => licenses.find(accountAccess => accountAccess.type === licenseType));

export const selectUserAllActiveLicensesByTypeAndAccountUserStatus = createSelector(
  selectUserAllAccountAccessesByActiveLicenseTypeAndAccountUserStatus,
  (accountAccesses, props) => accountAccesses.map(accountAccess => accountAccess.licenses.find(license => license.type === props.licenseType)) || []
);

export const selectUserCanEditActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, access => isActiveManager(access));

export const selectUserCanInviteToActiveAccount = createSelector(selectUserAccountAccessByActiveAccountId, access => isActiveManager(access));

export const selectUserManagedAccountsAndTrialPlan = createSelector(selectUserAllManagedAccountAccesses, selectUserCurrent, (accountAccesses, user) =>
  [...accountAccesses, user.trialPlan].filter(account => account && TypeUtil.getAccountAccessOrTrialPlanId(account))
);

export const selectUserSelectedManagedAccountOrTrialPlanByActiveId = createSelector(
  selectUserManagedAccountsAndTrialPlan,
  selectUserCurrent,
  selectAccountActiveAccountId,
  (accountAccesses, user, activeId) =>
    [...accountAccesses, user.trialPlan].find(account => account && TypeUtil.getAccountAccessOrTrialPlanId(account) === activeId)
);

export const selectUserSelectedAccountOrTrialPlanByActiveId = createSelector(
  selectUserActiveAccountAccesses,
  selectAccountActiveAccountId,
  (accountAccesses, activeId) => accountAccesses.find(account => account && account.accountId === activeId)
);

function isActiveManager(accAccess: AccountAccess): boolean {
  return accAccess && accAccess.status === AccountAccessStatus.ACTIVE && accAccess.role === AccountUserRole.MANAGER;
}

function accountOwner(email: string, accAccess: AccountAccess): boolean {
  return accAccess && accAccess.ownerEmail === email;
}
