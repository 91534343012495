import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, Observable, ObservedValueOf, of } from 'rxjs';
import { catchError, map, reduce, switchMap } from 'rxjs/operators';

import { UserService as AuthUserService, UserDetails } from '@celum/authentication';
import { BulkResultDTO, FailedEmailOperation, UserExistsResponse } from '@celum/sacc/domain';

import { SaccHttpHeaders } from '../sacc.http.headers';
import { RestService } from '../services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class UserResourceService extends RestService {
  private static CURRENT_USER_PATH = '/users/current';

  constructor(
    private httpClient: HttpClient,
    private authUserService: AuthUserService
  ) {
    super();
  }

  public getUserDetails(): Observable<UserDetails> {
    return this.authUserService.loadCurrentUser().pipe(switchMap(() => this.authUserService.currentUser$));
  }

  public updateUser(): Observable<UserDetails> {
    return this.httpClient.put<UserDetails>(this.getApiUrl(UserResourceService.CURRENT_USER_PATH), {});
  }

  public updateUserLanguage(locale: string): Observable<UserDetails> {
    return this.httpClient.put<UserDetails>(this.getApiUrl(UserResourceService.CURRENT_USER_PATH), { locale });
  }

  public exists(email: string): Observable<UserExistsResponse> {
    return this.httpClient.post<UserExistsResponse>(this.getApiUrl('/users/exists'), { email });
  }

  public deleteUserProfile(): Observable<void> {
    return this.httpClient.post<void>(this.getApiUrl(`${UserResourceService.CURRENT_USER_PATH}/GDPR`), {});
  }

  public deleteUsers(emails: string[]): Observable<BulkResultDTO<string, FailedEmailOperation>> {
    const bulkResult: BulkResultDTO<string, FailedEmailOperation> = {
      successful: [],
      failed: []
    };

    const obs = emails.map(email =>
      this.httpClient.post(this.getApiUrl('/users/GDPR'), { email }).pipe(
        map(() => ({
          email,
          success: true
        })),
        catchError(this.createPromotionError(email))
      )
    );

    return concat(...obs).pipe(
      reduce((acc, curr) => {
        if (curr.success) {
          acc.successful.push(curr.email);
        } else {
          const failedEmal: FailedEmailOperation = curr as FailedEmailOperation;
          acc.failed.push({ email: curr.email, reason: failedEmal.reason });
        }

        return acc;
      }, bulkResult)
    );
  }

  public promoteAdmins(emails: string[]): Observable<BulkResultDTO<string, FailedEmailOperation>> {
    const bulkResult: BulkResultDTO<string, FailedEmailOperation> = {
      successful: [],
      failed: []
    };

    const obs = emails.map(email =>
      this.httpClient.post(this.getApiUrl('/users/promote-admin'), { email }).pipe(
        map(() => ({
          email,
          success: true
        })),
        catchError(this.createPromotionError(email))
      )
    );

    return this.createSuccessfulAndFailedSetFromResponse(obs, bulkResult);
  }

  public promoteTechnicalUsers(emails: string[]): Observable<BulkResultDTO<string, FailedEmailOperation>> {
    const bulkResult: BulkResultDTO<string, FailedEmailOperation> = {
      successful: [],
      failed: []
    };
    const obs = emails.map(email =>
      this.httpClient.post(this.getApiUrl('/users/promote-technical-user'), { email }).pipe(
        map(() => ({
          email,
          success: true
        })),
        catchError(this.createPromotionError(email))
      )
    );

    return this.createSuccessfulAndFailedSetFromResponse(obs, bulkResult);
  }

  public startTrial(): Observable<any> {
    return this.httpClient.post(this.getApiUrl('/users/current/trialPlan'), {});
  }

  private createPromotionError(email: string): (error: any) => Observable<{ reason: any; success: boolean; email: string }> {
    return error =>
      of({
        email,
        success: false,
        reason: error.headers.get(SaccHttpHeaders.ERROR_KEY)
      });
  }

  private createSuccessfulAndFailedSetFromResponse(
    obs: Observable<ObservedValueOf<Observable<{ reason: any; success: boolean; email: string }>> | { success: boolean; email: string }>[],
    bulkResult: BulkResultDTO<string, FailedEmailOperation>
  ): Observable<BulkResultDTO<string, FailedEmailOperation>> {
    return concat(...obs).pipe(
      reduce((acc, curr) => {
        if (curr.success) {
          acc.successful.push(curr.email);
        } else {
          const failedEmail: FailedEmailOperation = curr as FailedEmailOperation;
          acc.failed.push({
            email: curr.email,
            reason: failedEmail.reason
          });
        }

        return acc;
      }, bulkResult)
    );
  }
}
