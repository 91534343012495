export enum MemberShipTableColumns {
  ProfilePicture = 'profile-picture',
  Name = 'name',
  Email = 'email',
  Status = 'status',
  InvitedBy = 'invited-by',
  AccountUserRole = 'account-user-role',
  Privileges = 'privileges',
  Actions = 'actions',
  Approval = 'approval',
  Groups = 'groups',
  Imported = 'imported'
}
