import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { AccountAccessStatus } from '@celum/authentication';
import { InvitationStatus } from '@celum/sacc/domain';

import { accountUserActions } from './account-user.action';
import { selectAccountUserFilter, selectAccountUserNextBatchParams } from './account-user.selectors';
import { ErrorFactory } from '../../error.factory';
import { AccountUserResourceService } from '../../services/account-user-resource.service';
import { userActions } from '../../store/user/user.action';
import { selectUserCurrent } from '../../store/user/user.selectors';
import { accountActions } from '../account/account.action';
import { selectAccountActiveAccountId } from '../account/account.selectors';
import { AppState } from '../app.state';
import { loaderActions } from '../loader/loader.action';
import { notificationActions } from '../notification/notification.action';

@Injectable()
export class AccountUserEffects {
  public showLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateServicePermissions, accountUserActions.updateStatus, accountUserActions.remove, accountUserActions.updateRole),
      map(() => loaderActions.show())
    )
  );

  public hideLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateSuccess, accountUserActions.updateFailure, accountUserActions.removeFailure, accountUserActions.removeSuccess),
      map(() => loaderActions.hide())
    )
  );

  public onAccountSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountActions.selectedAccountChanged),
      switchMap(action => {
        const actions = [];
        if (action.resetFilters) {
          actions.push(accountUserActions.resetAccountTableFilter());
        }
        if (action.resetTables) {
          actions.push(accountUserActions.resetAccountTable());
        }
        return from(actions);
      })
    )
  );

  // Reset table state and fetch first batch
  public onResetTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.resetAccountTable, accountUserActions.filterChanged),
      withLatestFrom(this.store$.select(selectAccountUserFilter)),
      map(() => accountUserActions.search({ resetSearch: false }))
    )
  );

  public onSortChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.sortChanged),
      map(() => accountUserActions.fetchBatch())
    )
  );

  // FIXME
  public onFetchBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.fetchBatch),
      withLatestFrom(this.store$.select(selectAccountUserNextBatchParams), this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([_, batchParams, accountId]) =>
        this.accountUserService.fetchBatch(accountId, batchParams).pipe(
          map(batch => accountUserActions.fetchBatchSuccess({ batch })),
          catchError(error => of(accountUserActions.fetchBatchFailure({ error })))
        )
      )
    )
  );

  public fetchBatchFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.fetchBatchFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.FETCH_USER_DETAILS_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.search),
      withLatestFrom(
        this.store$.select(selectAccountUserNextBatchParams),
        this.store$.select(selectAccountActiveAccountId),
        this.store$.select(selectAccountUserFilter)
      ),
      switchMap(([{ resetSearch }, batchParams, accountId, searchFilter]) => {
        const finalParams = { ...batchParams };
        if (resetSearch) {
          finalParams.continuationToken = '';
        }

        return this.accountUserService.search(accountId, searchFilter, [InvitationStatus.ACCEPTED, InvitationStatus.APPROVED], undefined, finalParams).pipe(
          map(batch => accountUserActions.searchSuccess({ batch })),
          catchError(error => of(accountUserActions.searchFailure({ error })))
        );
      })
    )
  );

  public updateAccountUserStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateStatus),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountUserService
          .updateAccountUser(
            accountId,
            action.accountUser.id,
            { status: action.accountUser.status, ...(action.keepScopes !== undefined && { keepScopes: action.keepScopes }) },
            action.accountUser
          )
          .pipe(
            map(accountUser => accountUserActions.updateSuccess({ accountUser, wasActivated: accountUser?.status === AccountAccessStatus.ACTIVE })),
            catchError(error => of(accountUserActions.updateFailure({ error })))
          )
      )
    )
  );

  public updateAccountUserRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateRole),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountUserService.updateAccountUserRole(accountId, action.accountUser).pipe(
          map(accountUser => accountUserActions.updateSuccess({ accountUser })),
          catchError(error => of(accountUserActions.updateFailure({ error })))
        )
      )
    )
  );

  public updateAccountUserServicePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateServicePermissions),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountUserService.updateAccountUserServicePermissions(accountId, action.accountUser).pipe(
          map(accountUser => accountUserActions.updateSuccess({ accountUser })),
          catchError(error => of(accountUserActions.updateFailure({ error })))
        )
      )
    )
  );

  public updateAccountUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.UPDATE_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public updateAccountUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateSuccess),
      map(params => {
        let messageKey = 'SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.UPDATE_SUCCESS';
        if (params.wasActivated !== undefined) {
          messageKey = params.wasActivated
            ? 'SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.ACTIVATED_SUCCESS'
            : 'SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.DEACTIVATED_SUCCESS';
        }
        return notificationActions.info({
          message: this.translateService.instant(messageKey)
        });
      })
    )
  );

  public reloadUserOnAccountUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.updateSuccess),
      withLatestFrom(this.store$.select(selectUserCurrent)),
      filter(([action, user]) => action.accountUser.email === user.email),
      map(() => userActions.getDetails())
    )
  );

  public onRemoveAccountUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.remove),
      withLatestFrom(this.store$.select(selectAccountActiveAccountId)),
      exhaustMap(([action, accountId]) =>
        this.accountUserService.removeAccountUser(accountId, action.accountUser.id, action.keepScopes).pipe(
          map(accountUserId => accountUserActions.removeSuccess({ accountUserId })),
          catchError(error => of(accountUserActions.removeFailure({ error })))
        )
      )
    )
  );

  public removeAccountUserFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.removeFailure),
      map(action =>
        notificationActions.error({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.REMOVAL_FAILURE', {
            error: ErrorFactory.getErrorMessage(action.error, this.translateService)
          })
        })
      )
    )
  );

  public removeAccountUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountUserActions.removeSuccess),
      map(() =>
        notificationActions.info({
          message: this.translateService.instant('SERVICES.ACCOUNT_USER.EFFECTS.ACCOUNT_USER.REMOVAL_SUCCESS')
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private accountUserService: AccountUserResourceService,
    private store$: Store<AppState>,
    private translateService: TranslateService
  ) {}
}
