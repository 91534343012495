import { Component } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'sacc-celum-footer',
  templateUrl: './celum-footer.component.html',
  styleUrls: ['./celum-footer.component.scss'],
  standalone: true,
  imports: [FlexModule, TranslateModule]
})
export class CelumFooterComponent {
  constructor() {
    // Placeholder
  }
}
