@if (service.vm$ | async; as vm) {
  <celum-dialog class="create-user-group-dialog" data-test-create-edit-user-group-dialog (onCloseAll)="dialogRef.close()">
    <celum-dialog-header class="create-user-group-dialog_top">
      <span>{{ vm.headerTextKey | translate }}</span>
    </celum-dialog-header>

    <form class="create-user-group-dialog_content" [formGroup]="userGroupForm">
      <div class="create-user-group-dialog_section">
        <div class="sacc-form-field">
          <mat-label required>{{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.GROUP_NAME' | translate }}</mat-label>
          @if (!vm.isReadonly) {
            <mat-form-field class="create-user-group-dialog_group-name-field">
              <input
                data-test-create-edit-user-group-dialog-name-input
                [formControl]="userGroupForm.controls.groupName"
                matInput
                maxLength="50"
                placeholder="{{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.GROUP_NAME_PLACEHOLDER' | translate }}" />
              <mat-hint>
                <celum-remaining-characters [control]="userGroupForm.controls.groupName" ngDefaultControl></celum-remaining-characters>
              </mat-hint>
              @if (userGroupForm.controls.groupName.hasError('required')) {
                <mat-error data-test-create-edit-user-group-dialog-input-error-required>{{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
              }
              @if (userGroupForm.controls.groupName.hasError('nameConflict')) {
                <mat-error data-test-create-edit-user-group-dialog-input-error-name-conflict>
                  {{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.NAME_CONFLICT_INPUT_ERROR' | translate }}
                </mat-error>
              }
            </mat-form-field>
          }
          @if (vm.isReadonly) {
            <span class="create-user-group-dialog_group-name-label" data-test-create-edit-user-group-dialog-group-name-label>
              {{ userGroupForm.controls.groupName.value }}
            </span>
          }
        </div>
      </div>
      <div class="create-user-group-dialog_section">
        <div class="create-user-group-dialog_user-selector">
          @if (!vm.isReadonly) {
            <div class="create-user-group-dialog_user-filter">
              <div class="create-user-group-dialog_label create-user-group-dialog_user-filter-label">
                {{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.ADD_GROUP_MEMBERS' | translate }}
              </div>
              <sacc-user-filter
                data-test-create-edit-user-group-dialog-user-filter
                [accountAccess]="data.accountAccess"
                [addedUsers]="vm.addedUsers"
                [removedUsers]="vm.removedUsers"
                [userGroup]="vm.userGroup"
                [userLimit]="userLimit"
                (userAdded)="service.addUser($event)"
                (userRemoved)="service.removeUser($event)"></sacc-user-filter>
            </div>
          }
          @if (vm.isReadonly) {
            <div class="create-user-group-dialog_label">{{ 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.GROUP_MEMBERS' | translate }}</div>
          }
          @if (vm.totalElementCount) {
            <div class="create-user-group-dialog_member-count">
              <sacc-table-count
                data-test-create-edit-user-group-dialog-member-count
                [count]="vm.totalElementCount"
                [countAsParameter]="false"
                [label]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.MEMBER_COUNT'"
                [labels]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.MEMBER_COUNTS'"></sacc-table-count>
            </div>
          }
          @if (vm.totalElementCount >= userLimit) {
            <message-box
              class="create-user-group-dialog_max-users-reached-message"
              data-test-create-edit-user-group-dialog-user-limit-message
              [text]="'COMPONENTS.USER_GROUPS.CREATE_DIALOG.MAX_NUMBER_REACHED' | translate: { userLimit: userLimit }"
              [type]="'warn'"></message-box>
          }
          @if (vm.totalElementCount === 0) {
            <empty-page
              class="create-user-group-dialog_user-list create-user-group-dialog_user-list--empty"
              data-test-create-edit-user-group-dialog-empty-page
              [config]="noResultsConfig"></empty-page>
          } @else {
            <celum-simple-list
              class="create-user-group-dialog_user-list"
              data-test-create-edit-user-group-dialog-user-list
              [class.create-user-group-dialog_user-list--full]="vm.totalElementCount >= userLimit"
              [hasMoreBottom]="vm.hasBottom"
              [items]="vm.usersToShow"
              [itemTemplate]="itemTemplate"
              [trackByFn]="trackByFn">
              <span inViewport (visible)="$event && service.loadNextBatch()" bottom-item></span>
            </celum-simple-list>
          }

          <ng-template #itemTemplate let-item>
            @if (!item.isAdded) {
              <div class="create-user-group-dialog_user-item">
                <div class="create-user-group-dialog_user-item-data">
                  <celum-user-avatar
                    class="create-user-group-dialog_user-item-avatar"
                    data-test-create-edit-user-group-dialog-
                    [accountAccessToken]="vm.token"
                    [class.disabled-item]="item.status === UserState.INACTIVE"
                    [user]="item"></celum-user-avatar>
                  <div class="create-user-group-dialog_user-item-data">
                    <div
                      class="create-user-group-dialog_user-item-name"
                      data-test-create-edit-user-group-dialog-member-name
                      spaceAwareTooltip
                      [class.disabled-item]="item.status === UserState.INACTIVE"
                      [matTooltip]="item | userName">
                      {{ item | userName }}
                    </div>
                  </div>
                </div>
                @if (!vm.isReadonly) {
                  <sacc-icon-button
                    data-test-create-edit-user-group-dialog-member-remove-button
                    [iconConfiguration]="removeIcon"
                    [matTooltip]="'COMMON.REMOVE' | translate"
                    (click)="service.removeUser(item)"></sacc-icon-button>
                }
              </div>
            }
          </ng-template>
        </div>
      </div>
    </form>

    <celum-dialog-footer
      [buttons]="vm.isReadonly ? ['close'] : ['cancel', 'ok']"
      [confirmBtnText]="vm.isReadonly ? 'COMMON.CLOSE' : vm.isEditMode ? 'COMMON.SAVE' : 'COMMON.CREATE'"
      [loading]="vm.loading"
      [valid]="this.userGroupForm.valid && (this.userGroupForm.dirty || vm.addedUsers.length > 0 || vm.removedUsers.length > 0)"
      (onCancel)="dialogRef.close(false)"
      (onConfirm)="vm.isReadonly ? dialogRef.close() : vm.isEditMode ? editUserGroup() : createUserGroup()"></celum-dialog-footer>
  </celum-dialog>
}
