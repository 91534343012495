export interface Federation {
  id: string;
  name: string;
  domainHint: string;
  type: FederationType;
  provisioning?: ProvisioningConfiguration;
  associatedAccounts: AccountAssociation[];
}

export interface ProvisioningConfiguration {
  type: ProvisioningType;
  emailDomains: string[];
  provisioningIdentity: string;
}

export interface AccountAssociation {
  accountId: string;
  accountName: string;
}

export enum ProvisioningType {
  MANUAL = 'manual',
  AUTOMATIC = 'automatic'
}

export enum FederationType {
  PUBLIC = 'public',
  PRIVATE = 'private'
}
