import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { take } from 'rxjs/operators';

import { AccountAccessStatus, AccountUserRole, ExperiencePrivilege, UserState, WorkroomPrivilege } from '@celum/authentication';
import { AVATAR_SIZE, CelumAvatarModule, CelumDialogModule, IconConfiguration } from '@celum/common-components';
import { DataUtil } from '@celum/core';
import { CelumListModule } from '@celum/internal-components';
import { CelumDirectivesModule } from '@celum/ng2base';
import { AccountAccess, SaccAccountUser } from '@celum/sacc/domain';
import { GroupToAvatarConfigPipe } from '@celum/sacc/shared';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { UserNamePipe } from '@celum/shared/util';

import { EditAccountMemberDialogService } from './edit-account-member-dialog.service';
import { PrivilegeInfoDialogComponent } from './privilege-info-dialog/privilege-info-dialog.component';
import { AvatarComponent } from '../../avatar/avatar.component';
import { IconButtonComponent } from '../../icon-button/icon-button.component';
import { IconTextButtonComponent } from '../../icon-text-button/icon-text-button.component';
import { PrivilegeIconComponent, PrivilegeIconType } from '../../privilege-icon/privilege-icon.component';
import { TableCountComponent } from '../../table-count/table-count.component';
import { AccountUserStatusSwitcherComponent } from '../account-user-status-switcher/account-user-status-switcher.component';
import { GroupSelectorComponent } from '../group-selector/group-selector.component';

export interface EditAccountMemberDialogData {
  editedUser: SaccAccountUser;
  accountAccess: AccountAccess;
  currentUser: SaccAccountUser;
}

@Component({
  selector: 'sacc-edit-account-member-dialog',
  templateUrl: './edit-account-member-dialog.component.html',
  styleUrls: ['./edit-account-member-dialog.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,

    CelumAvatarModule,
    CelumDialogModule,
    CelumDirectivesModule,
    CelumListModule,

    AccountUserStatusSwitcherComponent,
    AvatarComponent,
    GroupSelectorComponent,
    GroupToAvatarConfigPipe,
    IconButtonComponent,
    IconTextButtonComponent,
    PrivilegeIconComponent,
    TableCountComponent,
    UserAvatarComponent,
    UserNamePipe
  ],
  // Needed to style the layout of the dialog content correctly
  encapsulation: ViewEncapsulation.None,
  providers: [EditAccountMemberDialogService]
})
export class EditAccountMemberDialogComponent {
  private editedUser: SaccAccountUser;
  protected AVATAR_SIZE = AVATAR_SIZE;

  protected readonly ExperiencePrivilege = ExperiencePrivilege;
  protected readonly AccountAccessStatus = AccountAccessStatus;
  protected readonly UserState = UserState;
  protected readonly WorkroomPrivilege = WorkroomPrivilege;
  protected readonly PrivilegeIconType = PrivilegeIconType;

  @ViewChild('privilegesInfoIcon', { static: false, read: ElementRef }) protected privilegesInfoIcon: ElementRef;

  protected accountMemberForm: any;

  protected readonly icons = {
    experience: IconConfiguration.medium('logo-experience'),
    experienceInactive: IconConfiguration.medium('logo-experience-inactive'),
    work: IconConfiguration.medium('logo-workrooms'),
    workInactive: IconConfiguration.medium('logo-workrooms-inactive'),
    info: new IconConfiguration('info-icon').withIconSize(12),
    question: IconConfiguration.large('question-icon')
  };

  constructor(
    private formBuilder: FormBuilder,
    protected service: EditAccountMemberDialogService,
    protected dialogRef: MatDialogRef<EditAccountMemberDialogComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) protected data: EditAccountMemberDialogData
  ) {
    // For now, we map the experience-privilege to our internal enum - in the BE it is stored only with FULL_ACCESS or null
    this.editedUser = {
      ...data.editedUser,
      privileges: {
        ...data.editedUser.privileges,
        experience: data.editedUser.privileges.experience === ExperiencePrivilege.FULL_ACCESS ? ExperiencePrivilege.FULL_ACCESS : ExperiencePrivilege.NONE
      }
    };
    this.service.init(this.editedUser, data.accountAccess, data.currentUser);

    this.accountMemberForm = this.formBuilder.nonNullable.group({
      role: this.formBuilder.nonNullable.control<AccountUserRole>(this.editedUser.role),
      experiencePrivilege: this.formBuilder.nonNullable.control<ExperiencePrivilege>(this.editedUser.privileges.experience),
      status: this.formBuilder.nonNullable.control<AccountAccessStatus>(this.editedUser.status),
      workroomPrivilege: this.formBuilder.nonNullable.control<WorkroomPrivilege>(this.editedUser.privileges.work)
    });

    if (this.editedUser.status === AccountAccessStatus.INACTIVE) {
      this.accountMemberForm.controls.role.disable();
      this.accountMemberForm.controls.experiencePrivilege.disable();
      this.accountMemberForm.controls.workroomPrivilege.disable();
    }

    this.accountMemberForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (DataUtil.isEmpty(this.service.getChangedValues(this.accountMemberForm.value, this.editedUser))) {
        this.accountMemberForm.markAsPristine();
      }

      this.service.patchState({
        currentStatus: this.accountMemberForm.controls.status.value,
        workroomPrivilege: this.accountMemberForm.controls.workroomPrivilege.value
      });
    });
  }

  protected prepareUserActivation(activate: boolean): void {
    this.accountMemberForm.controls.status.patchValue(activate ? AccountAccessStatus.ACTIVE : AccountAccessStatus.INACTIVE);
    this.accountMemberForm.controls.status.markAsDirty();
  }

  protected updateAccountUser(keepSyncFiles?: boolean): void {
    const changes = this.service.getChangedValues(this.accountMemberForm.value, this.editedUser);

    // Special case permissions: If at least work- or experience-permissions are changed, we need to provide both for the backend
    if (changes.privileges) {
      changes.privileges.experience = changes.privileges.experience || this.editedUser.privileges.experience;
      changes.privileges.work = changes.privileges.work || this.editedUser.privileges.work;
    }

    if (keepSyncFiles !== undefined) {
      changes.canKeepDriveScopes = keepSyncFiles;
    }

    this.service.updateAccountUser(changes);
  }

  protected getReadonlyRoleLabel(editedUser: SaccAccountUser, accountAccess: AccountAccess): string {
    const roleKey = `COMPONENTS.ACCOUNT_USER_TABLE.USER_ROLE.${editedUser.role.toUpperCase()}`;
    return editedUser.email === accountAccess.ownerEmail ? `${roleKey}_OWNER` : roleKey;
  }

  protected onStatusSwitcherClicked(): void {
    this.service.vm$.pipe(take(1)).subscribe(vm => {
      const currentStatus = this.accountMemberForm.controls.status.value;
      if (vm.editedUser.status === AccountAccessStatus.ACTIVE && currentStatus === AccountAccessStatus.ACTIVE) {
        this.prepareUserActivation(false);
      } else if (vm.editedUser.status === AccountAccessStatus.INACTIVE && currentStatus === AccountAccessStatus.INACTIVE) {
        this.prepareUserActivation(true);
      }
    });
  }

  protected showPrivilegesInfoPanel(): void {
    this.service.patchState({ isPopupOpen: true });
    const rect = this.privilegesInfoIcon.nativeElement.getBoundingClientRect();
    const options: MatDialogConfig = {
      panelClass: 'sacc-popup_panel',
      backdropClass: 'sacc-popup_invisible-backdrop',
      height: '15rem',
      width: '29.3rem',
      maxHeight: '15rem',
      maxWidth: '29.3rem',
      autoFocus: 'dialog',
      position: { left: `${rect.left}px`, top: `${rect.bottom}px` }
    };
    this.matDialog
      .open(PrivilegeInfoDialogComponent, options)
      .afterClosed()
      .subscribe(() => this.service.patchState({ isPopupOpen: false }));
  }
}
