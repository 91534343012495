import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap, throwError } from 'rxjs';

import { SaccAccountUser, SaccProperties, UserGroup, UserGroupMembersAddedResponse } from '@celum/sacc/domain';
import { AccountUser, PaginationOption } from '@celum/shared/domain';
import { Result } from '@celum/shared/util';

import { Utils } from '../utils';

export enum UserGroupMemberAddedError {
  CREATION_FAILED = 555,
  NAME_CONFLICT = 409
}

export type UserGroupSorter = {
  key: keyof Pick<AccountUser, 'firstName' | 'lastName' | 'email'>;
  order: 'desc' | 'asc';
};

export interface UserGroupsFindOptions extends PaginationOption {
  ids?: string[];
  filter?: string;
  imported?: boolean;
  sort?: UserGroupSorter;
  continuationToken?: string;
}

@Injectable({ providedIn: 'root' })
export class UserGroupResourceService {
  constructor(private http: HttpClient) {}

  public deleteUserGroup(accountId: string, groupId: string): any {
    return this.http.delete<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${groupId}`);
  }

  public createUserGroup(name: string, emails: string[], accountId: string): any {
    return this.http.post<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups`, { name }).pipe(
      switchMap(userGroup => this.createGroupMemberOperations(emails, accountId, userGroup.id)),
      switchMap((response: UserGroupMembersAddedResponse[]) => {
        const errors = response?.map(resp => resp.failedAddedEmails).flat();
        if (errors.length > 0) {
          return throwError(() => new HttpErrorResponse({ status: UserGroupMemberAddedError.CREATION_FAILED, error: errors }));
        }

        return of(response);
      })
    );
  }

  public editUserGroup(
    groupId: string,
    name: string,
    emailsToAdd: string[],
    emailsToRemove: string[],
    accountId: string
  ): Observable<UserGroupMembersAddedResponse[]> {
    return this.http.patch<UserGroup>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${groupId}`, { name }).pipe(
      switchMap(userGroup => this.createGroupMemberOperations(emailsToAdd, accountId, userGroup.id, 100, emailsToRemove)),
      switchMap((response: UserGroupMembersAddedResponse[]) => {
        const errors = response?.map(resp => resp.failedAddedEmails).flat();
        if (errors.length > 0) {
          return throwError(() => new HttpErrorResponse({ status: UserGroupMemberAddedError.CREATION_FAILED, error: errors }));
        }

        return of(response);
      })
    );
  }

  public searchUserGroupsForAccount(accountId: string, options: Partial<UserGroupsFindOptions> = { offset: 0, limit: 250 }): Observable<Result<UserGroup>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 250,
      sort: `${options?.sort?.key || 'name'},${options?.sort?.order || 'asc'}`,
      imported: options?.imported || false
    };

    const params = new HttpParams()
      .set('page', finalOptions.offset / finalOptions.limit)
      .set('size', finalOptions.limit)
      .set('sort', finalOptions.sort);

    return this.http
      .post<{
        entities: UserGroup[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(
        `${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/search`,
        { filter: finalOptions.filter, imported: finalOptions.imported },
        { params }
      )
      .pipe(map(result => Utils.mapToResult<UserGroup>(result, finalOptions.offset)));
  }

  public getUserGroupsForAccount(accountId: string, options: Partial<UserGroupsFindOptions> = { offset: 0, limit: 1 }): Observable<Result<UserGroup>> {
    const finalOptions = {
      ...options,
      offset: options?.offset || 0,
      limit: options?.limit || 1,
      sort: `${options?.sort?.key || 'name'},${options?.sort?.order || 'asc'}`
    };

    const params = new HttpParams()
      .set('page', finalOptions.offset / finalOptions.limit)
      .set('size', finalOptions.limit)
      .set('sort', finalOptions.sort);

    return this.http
      .get<{
        entities: UserGroup[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups`, { params })
      .pipe(map(result => Utils.mapToResult<UserGroup>(result, finalOptions.offset)));
  }

  public getUserGroupMembers(accountId: string, userGroupId: string, options: PaginationOption = { offset: 0, limit: 1 }): Observable<Result<SaccAccountUser>> {
    const params = new HttpParams()
      .set('page', options.offset / options.limit)
      .set('size', options.limit)
      .set('sort', '_ts,desc');

    return this.http
      .get<{
        entities: SaccAccountUser[];
        totalCount: number;
        filterCount: number;
        continuationToken: string;
      }>(`${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${userGroupId}/members`, { params })
      .pipe(map(result => Utils.mapToResult<SaccAccountUser>(result, options.offset)));
  }

  private createGroupMemberOperations(
    emailsToAdd: string[],
    accountId: string,
    userGroupId: string,
    chunkSize = 100,
    emailsToRemove?: string[]
  ): Observable<UserGroupMembersAddedResponse[]> {
    if (emailsToAdd.length === 0 && (emailsToRemove === undefined || emailsToRemove.length === 0)) {
      return of([
        {
          failedAddedEmails: []
        }
      ]);
    }

    const operations = [];
    const url = `${SaccProperties.properties.apiUrl}/accounts/${accountId}/groups/${userGroupId}/members`;

    for (let i = 0; i < emailsToAdd.length; i += chunkSize) {
      const chunk = emailsToAdd.slice(i, i + chunkSize);
      operations.push(this.http.patch<UserGroupMembersAddedResponse>(url, { emailsToAdd: chunk }));
    }

    for (let i = 0; i < emailsToRemove?.length; i += chunkSize) {
      const chunk = emailsToRemove.slice(i, i + chunkSize);
      operations.push(this.http.patch<UserGroupMembersAddedResponse>(url, { emailsToRemove: chunk }));
    }

    return forkJoin(operations);
  }
}
