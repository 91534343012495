import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AccountAccessStatus, AuthService, ExperiencePrivilege, License, LicenseType } from '@celum/authentication';
import { DataUtil, tapAllResponses } from '@celum/core';
import { AccountAccess, InvitationStatus, SaccAccountUser, UserGroup } from '@celum/sacc/domain';
import {
  AccountUserChanges,
  AccountUserResourceService,
  AppState,
  InvitationChanges,
  NotificationService,
  selectUserLicenseByActiveAccountIdAndLicenseType
} from '@celum/sacc/shared';
import { ErrorService } from '@celum/shared/util';

import { EditInvitationDialogComponent } from './edit-invitation-dialog.component';
import { AccountUserStatusSwitcherConfig } from '../account-user-status-switcher/account-user-status-switcher.component';

export type EditInvitationDialogState = {
  editedInvitation?: any; // have to cast this for now, because status of SaccAccountUser != status of AccountUser
  currentUser?: SaccAccountUser;
  token?: string;
  tokenExpiration?: number;
  userGroup?: UserGroup;
  isReadonly?: boolean;
  addedGroups: UserGroup[];
  removedGroups: UserGroup[];
  accountAccess?: AccountAccess;
  isRefreshingToken?: boolean;
  currentStatus?: InvitationStatus;
  resendInvitation: boolean;
};

export type EditInvitationDialogViewModel = {
  hasExperienceAccess: boolean;
  hasWorkroomLicense: boolean;
  isEditMode: boolean;
  headerTextKey: string;
  statusSwitcherConfig: AccountUserStatusSwitcherConfig;
} & Omit<EditInvitationDialogState, 'userGroup'>;

export interface EditInvitationFormValue {
  status?: InvitationStatus;
  permissions?: {
    createWorkrooms?: boolean;
    finishWorkrooms?: boolean;
    manageTemplates?: boolean;
  };
  experience?: boolean;
  resendInvitation?: boolean;
}

@Injectable()
export class EditInvitationDialogService extends ComponentStore<EditInvitationDialogState> {
  public vm$ = this.select(
    this.state$,
    this.store$.select(selectUserLicenseByActiveAccountIdAndLicenseType(LicenseType.WORK)).pipe(map((license: License) => license && license.active)),
    (state, hasWorkroomLicense) => this.createViewModel(state, hasWorkroomLicense)
  );

  public updateInvitation = this.effect<AccountUserChanges>(update$ =>
    update$.pipe(
      switchMap(changes =>
        this.accountUserService.updateAccountUser(this.get().editedInvitation.accountId, this.get().editedInvitation.id, changes).pipe(map(() => changes))
      ),
      tapAllResponses<AccountUserChanges, HttpErrorResponse>(
        changes => {
          if (changes.status) {
            const messageKey =
              changes.status === AccountAccessStatus.INACTIVE
                ? 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.SNACKBAR.SUCCESS_DEACTIVATE'
                : 'COMPONENTS.EDIT_ACCOUNT_MEMBER_DIALOG.SNACKBAR.SUCCESS_ACTIVATE';
            this.notificationService.info(messageKey);
          }

          this.dialogRef.close(true);
        },
        error => {
          // TODO: Handle errors (like e.g. one or many groups have reached their limits) with https://celum.atlassian.net/browse/SACC-3556
          const errors = this.extractErrorsFromResponse(error);
          if (!DataUtil.isEmpty(errors)) {
            errors.forEach(errorKey => this.errorService.error('EditAccountMemberDialogService', errorKey, error));
          } else {
            this.errorService.httpError(error, 'EditAccountMemberDialogService');
          }

          this.dialogRef.close(false);
        }
      )
    )
  );

  constructor(
    private authService: AuthService,
    private accountUserService: AccountUserResourceService,
    protected dialogRef: MatDialogRef<EditInvitationDialogComponent>,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private store$: Store<AppState>
  ) {
    super({
      addedGroups: [],
      removedGroups: [],
      resendInvitation: false
    });

    this.select(state => state)
      .pipe(
        filter(state => !state.isRefreshingToken && (!state.token || state.tokenExpiration === undefined || Date.now() > state.tokenExpiration)),
        switchMap(() => {
          this.patchState({ isRefreshingToken: true });
          return this.authService.getAuthResult().pipe(
            tap(result => {
              this.patchState({ tokenExpiration: result.expiresOn.getTime(), token: result.idToken });
              this.patchState({ isRefreshingToken: false });
            })
          );
        }),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public init(editedInvitation: SaccAccountUser, accountAccess: AccountAccess, currentUser: SaccAccountUser): void {
    this.patchState({ editedInvitation: { ...editedInvitation }, accountAccess, currentUser, currentStatus: editedInvitation.invitationStatus });
  }

  public getChangedValues(formValue: EditInvitationFormValue, initialEditedInvitation: SaccAccountUser): InvitationChanges {
    const changedValues: Partial<InvitationChanges> = {};

    if (formValue.status !== initialEditedInvitation.invitationStatus) {
      changedValues.invitationStatus = formValue.status;
    }

    const addedGroups = this.get().addedGroups;
    if (!DataUtil.isEmpty(addedGroups)) {
      changedValues.groupIdsToAdd = addedGroups.map(group => group.id);
    }

    const removedGroups = this.get().removedGroups;
    if (!DataUtil.isEmpty(removedGroups)) {
      changedValues.groupIdsToRemove = removedGroups.map(group => group.id);
    }

    // TODO: Implement check for privileges once the dialog is re-implemented in https://celum.atlassian.net/browse/SACC-3163
    /*const workroomPermissions = [
     formValue.permissions.createWorkrooms && WorkroomPermission.CREATE_WORKROOMS,
     formValue.permissions.finishWorkrooms && WorkroomPermission.FINISH_WORKROOMS,
     formValue.permissions.manageTemplates && WorkroomPermission.MANAGE_TEMPLATES
     ].filter(Boolean);

     const experiencePermissions = formValue.experience ? [ExperiencePermissions.FULL_ACCESS] : [];

     if (!isArrayEqual(workroomPermissions, initialEditedInvitation.workroomPermissions)) {
     changedValues.workroomPermissions = workroomPermissions;
     }

     if (!isArrayEqual(experiencePermissions, initialEditedInvitation.experiencePermissions)) {
     changedValues.experiencePermissions = experiencePermissions;
     }*/

    if (this.get().resendInvitation) {
      changedValues.resendInvitation = true;
    }

    return changedValues;
  }

  private createViewModel(state: EditInvitationDialogState, hasWorkroomLicense: boolean): EditInvitationDialogViewModel {
    const isEditMode = !!state.userGroup && !state.isReadonly;

    return {
      ...state,
      hasExperienceAccess: (state.editedInvitation as SaccAccountUser).privileges.experience === ExperiencePrivilege.FULL_ACCESS,
      hasWorkroomLicense,
      isEditMode,
      headerTextKey: state.isReadonly
        ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.READONLY'
        : isEditMode
          ? 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.EDIT'
          : 'COMPONENTS.USER_GROUPS.CREATE_DIALOG.HEADER.CREATE',
      statusSwitcherConfig: EditInvitationDialogService.getStatusSwitcherConfig(state),
      resendInvitation: state.resendInvitation
    };
  }

  private extractErrorsFromResponse(error: HttpErrorResponse): string[] {
    return error?.error?.errors.map((errorElement: { errorKey: string }) => errorElement.errorKey);
  }

  private static getStatusSwitcherConfig(state: EditInvitationDialogState): AccountUserStatusSwitcherConfig {
    let config = {
      userOrInvitation: state.editedInvitation,
      valueNotYetChanged: state.editedInvitation.invitationStatus === state.currentStatus,
      isInvitation: true
    } as AccountUserStatusSwitcherConfig;

    if (state.editedInvitation.invitationStatus === InvitationStatus.INVITED) {
      const enableResend = state.editedInvitation.resendAvailableAt && new Date(state.editedInvitation.resendAvailableAt).getTime() <= Date.now();
      config = {
        ...config,
        buttonIcon: 'user-deactivate',
        buttonLabel: 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.RESEND',
        disabled: !enableResend,
        buttonTooltip: !enableResend ? 'COMPONENTS.USER_INVITATION_TABLE.OPTIONS.RESEND_INVITATION_DISABLED' : '',
        switchedTextHeader: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_HEADER',
        switchedText: 'COMPONENTS.EDIT_INVITATION_DIALOG.STATUS.RESEND_INFO_TEXT'
      };
    }

    return config;
  }
}
