import { Result } from '@celum/shared/util';

import { EntitiesResult } from './entities-result';

export class Utils {
  private static readonly emailRegex =
    /^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  private static readonly domainAndEmailRegex =
    /^([a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@)??(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

  public static deepCopy<T>(obj: T): T {
    // https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
  }

  public static isEmail(input: string): boolean {
    return Utils.emailRegex.test(input.toLowerCase());
  }

  public static isEmailOrDomain(input: string): boolean {
    return Utils.domainAndEmailRegex.test(input.toLowerCase());
  }

  public static getAccountLogo(accountLogoDownloadLink: string, accountAccessToken: string): string {
    if (accountLogoDownloadLink && accountAccessToken) {
      return `${accountLogoDownloadLink}&token=${accountAccessToken}`;
    }
    return 'assets/images/missing-company-logo.svg';
  }

  public static calculateBatchSize(accountRecordHeight: number): number {
    // 1.2 is a magic number that adds a little bit of extra buffer to the batchsize
    return Math.round((window.innerHeight / accountRecordHeight) * 1.2);
  }

  public static mapToResult<T>(result: EntitiesResult<T>, offset: number): Result<T> {
    return {
      paginationInfo: {
        totalElementCount: result.totalCount,
        hasBottom: !!result.continuationToken,
        hasTop: offset !== 0
      },
      data: result.entities
    };
  }
}
